import { useState } from 'react';

import {
  type CurrenciesKey,
  SORTED_CURRENCY_OPTIONS,
} from 'src/core/config/money';

import { FilteredEntityListWrapper } from './FilteredEntityListWrapper';
import { EntityListContainer } from './entity-list/EntityListContainer';
import { EntityListFilters } from './entity-list-filters/EntityListFilters';
import { useOrganisationCompanyList } from '../../hooks/data/useOrganisationCompanyList';
import type {
  ExtendedOrganisationReportingEntity,
  OrganisationReportingEntity,
} from '../../types';
import { FilteredListBySearchWrapper } from '../common/FilteredListBySearchWrapper';

type Props = {
  onDataLoaded: () => void;
};

export const MultiEntityHubOverviewPage = ({ onDataLoaded }: Props) => {
  const [orgEntities, setOrgEntities] = useState<
    (OrganisationReportingEntity | ExtendedOrganisationReportingEntity)[]
  >(useOrganisationCompanyList());

  const [isFilteringEnabled, setIsFilteringEnabled] = useState(false);
  const orgCurrencies = [
    ...new Set(orgEntities.map(({ currency }) => currency)),
  ];
  const orgCurrencyOptions = SORTED_CURRENCY_OPTIONS.filter(({ key }) =>
    orgCurrencies.includes(key as CurrenciesKey),
  ) as { key: CurrenciesKey; label: string }[];

  return (
    <FilteredListBySearchWrapper
      items={orgEntities}
      searchableFields={['name']}
    >
      {({ filteredItems: entitiesFromSearch, search, setSearch }) => (
        <FilteredEntityListWrapper entities={entitiesFromSearch}>
          {({ filteredEntities, filters, setFilters }) => (
            <div className="flex flex-col gap-s">
              <div className="flex flex-col gap-xxs">
                <EntityListFilters
                  filters={filters}
                  setFilters={setFilters}
                  filtersConfig={{
                    currencyOptions: orgCurrencyOptions,
                  }}
                  search={search}
                  setSearch={setSearch}
                  isFilteringEnabled={isFilteringEnabled}
                />
                <hr className="separator" style={{ borderTop: 'none' }} />
              </div>

              <EntityListContainer
                entities={filteredEntities}
                onDataLoaded={(entities) => {
                  setOrgEntities(entities);
                  setIsFilteringEnabled(true);
                  onDataLoaded();
                }}
              />
            </div>
          )}
        </FilteredEntityListWrapper>
      )}
    </FilteredListBySearchWrapper>
  );
};
