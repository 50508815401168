import { useLocalStorage } from 'react-use';

import { LocalStorageKey } from 'src/core/constants/storage';

import { type OrganisationReportingEntity } from '../types';

export const usePinnedEntities = () => {
  const [pinnedEntitiesFromStorage, setPinnedEntitiesFromStorage] =
    useLocalStorage<string[]>(
      LocalStorageKey.OrganisationReportingPinnedEntities,
      [],
    );
  const handleTogglePinnedEntity = (entityId: string) => {
    const currentPinnedEntities = pinnedEntitiesFromStorage ?? [];
    const newPinnedEntities = currentPinnedEntities.includes(entityId)
      ? currentPinnedEntities.filter((id) => id !== entityId)
      : [...currentPinnedEntities, entityId];
    setPinnedEntitiesFromStorage(newPinnedEntities);
  };

  const isPinned = (entity: OrganisationReportingEntity) =>
    pinnedEntitiesFromStorage?.includes(entity.id) ?? false;

  return {
    pinnedEntities: pinnedEntitiesFromStorage,
    handleTogglePinnedEntity,
    isPinned,
  };
};
