import { Button, Input } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type CurrenciesKey } from 'src/core/config/money';

import { EntityListFilter } from './EntityListFilter';

type EntityStatusFilterValue = 'active' | 'inactive';
type FundsStatusFilterValue = 'available' | 'shortfall';

export type EntityFilters = {
  currencyFilters: CurrenciesKey[];
  entityStatusFilters: EntityStatusFilterValue[];
  fundsStatusFilters: FundsStatusFilterValue[];
};

export const DEFAULT_ENTITY_FILTERS: EntityFilters = {
  currencyFilters: [],
  entityStatusFilters: [],
  fundsStatusFilters: [],
};

type Props = {
  filters: EntityFilters;
  setFilters: (value: EntityFilters) => void;
  filtersConfig: {
    currencyOptions: { key: CurrenciesKey; label: string }[];
  };
  search: string;
  setSearch: (value: string) => void;
  isFilteringEnabled?: boolean;
};

export const EntityListFilters = ({
  filters,
  setFilters,
  filtersConfig: { currencyOptions },
  search,
  setSearch,
  isFilteringEnabled = false,
}: Props) => {
  const { t } = useTranslation('global');

  const filterOptions: {
    currencyFilters: { key: CurrenciesKey; label: string }[];
    entityStatusFilters: { key: EntityStatusFilterValue; label: string }[];
    fundsStatusFilters: { key: FundsStatusFilterValue; label: string }[];
  } = {
    currencyFilters: currencyOptions,
    entityStatusFilters: [
      {
        key: 'active',
        label: t(
          'organisation.reporting.page.entities.filters.entityStatus.values.active',
        ),
      },
      {
        key: 'inactive',
        label: t(
          'organisation.reporting.page.entities.filters.entityStatus.values.inactive',
        ),
      },
    ],
    fundsStatusFilters: [
      {
        key: 'shortfall',
        label: t(
          'organisation.reporting.page.entities.filters.fundsStatus.values.shortfall',
        ),
      },
      {
        key: 'available',
        label: t(
          'organisation.reporting.page.entities.filters.fundsStatus.values.available',
        ),
      },
    ],
  };

  const [currencyFilters, setCurrencyFilters] = useState<CurrenciesKey[]>(
    filters.currencyFilters,
  );
  const [entityStatusFilters, setEntityStatusFilters] = useState<
    EntityStatusFilterValue[]
  >(filters.entityStatusFilters);
  const [fundsStatusFilters, setFundsStatusFilters] = useState<
    FundsStatusFilterValue[]
  >(filters.fundsStatusFilters);

  const hasActiveFilters = [
    currencyFilters,
    entityStatusFilters,
    fundsStatusFilters,
  ].some((f) => f.length > 0);

  const resetFilters = () => {
    if (hasActiveFilters) {
      setCurrencyFilters([]);
      setEntityStatusFilters([]);
      setFundsStatusFilters([]);

      setFilters({
        currencyFilters: [],
        entityStatusFilters: [],
        fundsStatusFilters: [],
      });
    }
  };

  return (
    <div key="filters" className="flex flex-row justify-between gap-l">
      <div className="flex items-center">
        {filterOptions.currencyFilters.length > 1 && (
          <EntityListFilter
            label={t(
              'organisation.reporting.page.entities.filters.currency.label',
            )}
            activeFilters={currencyFilters ?? []}
            setActiveFilters={(values) => {
              setCurrencyFilters(values);
              setFilters({
                ...filters,
                currencyFilters: values,
              });
            }}
            filterOptions={filterOptions.currencyFilters}
            isDisabled={!isFilteringEnabled}
          />
        )}
        <EntityListFilter
          label={t(
            'organisation.reporting.page.entities.filters.entityStatus.label',
          )}
          activeFilters={entityStatusFilters}
          setActiveFilters={(values) => {
            setEntityStatusFilters(values);
            setFilters({
              ...filters,
              entityStatusFilters: values,
            });
          }}
          filterOptions={filterOptions.entityStatusFilters}
          isDisabled={!isFilteringEnabled}
          isMonoValue
        />
        <EntityListFilter
          label={t(
            'organisation.reporting.page.entities.filters.fundsStatus.label',
          )}
          activeFilters={fundsStatusFilters ?? []}
          setActiveFilters={(values) => {
            setFundsStatusFilters(values);
            setFilters({
              ...filters,
              fundsStatusFilters: values,
            });
          }}
          filterOptions={filterOptions.fundsStatusFilters}
          isDisabled={!isFilteringEnabled}
          isMonoValue
        />
        <Button
          variant="ghost"
          iconName="cross"
          iconPosition="left"
          isDisabled={!isFilteringEnabled}
          className={classNames(
            'title-m',
            hasActiveFilters ? 'text-complementary' : 'text-neutral-dark',
          )}
          text={t('organisation.reporting.page.entities.filters.clear')}
          onClick={resetFilters}
        />
      </div>
      <div>
        <Input
          isDisabled={!isFilteringEnabled}
          className="min-w-[312px]"
          placeholder={t(
            'organisation.reporting.page.entities.searchPlaceholder',
          )}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
    </div>
  );
};
