import {
  Avatar,
  Button,
  Link,
  Modal,
  TextInput,
  FormField,
  CheckboxField,
  Icon,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import {
  fallbackSupplierLogoSrc,
  SupplierLogo,
} from 'common/components/SupplierLogo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { getBrandName } from 'src/core/modules/bookkeep/integration/name';
import { integrationsOnEMVPFlow } from 'src/core/modules/bookkeep/settings/accounting/components/AccountingBasePage/components/helper';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import styles from './PreloginModal.module.css';
import {
  type AccountingSoftware,
  type NativeAccountingIntegration,
} from '../../../../../../integration/status';
import { type Oauth1TokenSet } from '../../../../../accounting/graphql/hooks';
import { getNativeAccountingIntegrationDetails } from '../../../../helper';

type Oauth1Errors = {
  accountId: boolean;
  consumerId: boolean;
  consumerSecret: boolean;
};

export const PreloginModal = ({
  integration,
  isAccountingSwitching,
  isOpen,
  tokenSet,
  setTokenSet,
  onCancel,
  onConfirm,
}: {
  integration: NativeAccountingIntegration;
  isAccountingSwitching: boolean;
  isOpen: boolean;
  tokenSet: Oauth1TokenSet;
  setTokenSet: (tokenSet: Oauth1TokenSet) => void;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();

  const [oAuth1Errors, setOAuth1Errors] = useState<Oauth1Errors>({
    accountId: false,
    consumerId: false,
    consumerSecret: false,
  });
  const [hasClickedConfirm, setHasClickedConfirm] = useState(false);
  const [isConfirmInitiallyDisabled, setIsConfirmInitiallyDisabled] = useState(
    integrationsOnEMVPFlow.includes(integration),
  );

  useEffect(() => {
    setOAuth1Errors({
      accountId: oAuth1Errors.accountId && !tokenSet.accountId.length,
      consumerId: oAuth1Errors.consumerId && !tokenSet.consumerId.length,
      consumerSecret:
        oAuth1Errors.consumerSecret && !tokenSet.consumerSecret.length,
    });
  }, [tokenSet]);

  useEffect(() => {
    setHasClickedConfirm(false);
  }, [isOpen]);

  const { i18nSelectTitle, logoPath, i18nPreloginTitle, i18nPreloginConfirm } =
    getNativeAccountingIntegrationDetails(integration);

  const isTokenSetValid = () => {
    const currentErrors = {
      accountId: !tokenSet.accountId.length,
      consumerId: !tokenSet.consumerId.length,
      consumerSecret: !tokenSet.consumerSecret.length,
    };

    setOAuth1Errors(currentErrors);

    return (
      !currentErrors.accountId &&
      !currentErrors.consumerId &&
      !currentErrors.consumerSecret
    );
  };

  const onClick = () => {
    if (integration === 'Netsuite' && !isTokenSetValid()) {
      return;
    }
    setHasClickedConfirm(true);
    onConfirm();
  };

  return (
    <Modal
      className={styles.preloginModal}
      title={t(i18nPreloginTitle, { integration })}
      isOpen={isOpen}
      illustration={getModalIllustration(
        logoPath,
        t(i18nSelectTitle),
        integration,
      )}
      illustrationHeight={
        integrationsOnEMVPFlow.includes(integration) ? '64' : '80'
      }
      actions={[
        <Button
          key="no"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          onClick={onClick}
          isDisabled={isConfirmInitiallyDisabled || hasClickedConfirm}
          text={t(i18nPreloginConfirm(isAccountingSwitching), { integration })}
          variant="primary"
        />,
      ]}
    >
      <ModalContent
        integration={integration}
        isSwitching={isAccountingSwitching}
        tokenSet={tokenSet}
        setTokenSet={setTokenSet}
        oAuth1Errors={oAuth1Errors}
        isConfirmInitiallyDisabled={isConfirmInitiallyDisabled}
        setIsConfirmInitiallyDisabled={setIsConfirmInitiallyDisabled}
      />
    </Modal>
  );
};

const getModalIllustration = (
  logoPath: string,
  translatedI18nTitle: string,
  integration: NativeAccountingIntegration,
): React.ReactNode => {
  if (!integrationsOnEMVPFlow.includes(integration)) {
    return (
      <div className={styles.integrationIllustrationContainer}>
        <Avatar
          variant="square"
          className={styles.integrationIllustrationAvatar}
          src={logoPath}
          fallbackSrc={fallbackSupplierLogoSrc}
          text={translatedI18nTitle}
          size="l"
          badgeProps={{
            variant: 'circle',
            text: 'Spendesk',
            src: '/static/img/spendesk_logo.svg',
          }}
        />
      </div>
    );
  }

  const integrationLogoName = getIntegrationLogoName(integration);

  return (
    <div
      className={classNames(
        styles.integrationIllustrationContainer,
        styles.integrationIllustrationContainer__mvpFlow,
      )}
    >
      <SupplierLogo name="Spendesk" size="l" />
      <Icon name="sync" size="l" />
      <SupplierLogo name={integrationLogoName} size="l" />
    </div>
  );
};

const getIntegrationLogoName = (integration: NativeAccountingIntegration) => {
  switch (integration) {
    case 'Datev':
      return 'Datev';
    case 'Xero':
      return 'Xero';
    case 'Quickbooks':
      return 'Quickbooks';
    case 'Netsuite':
      return 'Netsuite';
    case 'Sage100':
      return 'Sage';
    case 'ACD':
      return 'ACD';
    case 'Odoo':
      return 'Odoo';
    case 'ExactOnlineFr':
      return 'Exact Online FR';
    default:
      rejectUnexpectedValue('native integration', integration);
  }
};

const ModalContent = ({
  integration,
  isSwitching,
  tokenSet,
  setTokenSet,
  oAuth1Errors,
  isConfirmInitiallyDisabled,
  setIsConfirmInitiallyDisabled,
}: {
  integration: NativeAccountingIntegration;
  isSwitching: boolean;
  tokenSet: Oauth1TokenSet;
  setTokenSet: (tokenSet: Oauth1TokenSet) => void;
  oAuth1Errors: Oauth1Errors;
  isConfirmInitiallyDisabled: boolean;
  setIsConfirmInitiallyDisabled: (isConfirmInitiallyDisabled: boolean) => void;
}) => {
  switch (integration) {
    case 'Datev':
      return (
        <DatevModalContent
          integration={integration}
          isSwitching={isSwitching}
        />
      );
    case 'Xero':
      return (
        <XeroModalContent integration={integration} isSwitching={isSwitching} />
      );
    case 'Quickbooks':
      return (
        <QuickbooksModalContent
          integration={integration}
          isSwitching={isSwitching}
        />
      );
    case 'Netsuite':
      return (
        <NetsuiteModalContent
          integration={integration}
          isSwitching={isSwitching}
          tokenSet={tokenSet}
          setTokenSet={setTokenSet}
          oAuth1Errors={oAuth1Errors}
        />
      );
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return (
        <ChiftModalContent
          isConfirmInitiallyDisabled={isConfirmInitiallyDisabled}
          setIsConfirmInitiallyDisabled={setIsConfirmInitiallyDisabled}
          integration={integration}
        />
      );
    default:
      rejectUnexpectedValue('native integration', integration);
  }
};

const DatevModalContent = ({
  integration,
  isSwitching,
}: {
  integration: NativeAccountingIntegration;
  isSwitching: boolean;
}) => {
  const { t } = useTranslation();
  const companyId = useCompanyId();
  return (
    <div className={styles.infoWrapper}>
      <p className={styles.subtitle}>
        <span>
          <Trans
            integration={integration}
            i18nKey={getNativeAccountingIntegrationDetails(
              integration,
            ).i18nPreloginSubtitle(isSwitching)}
          >
            text
            <Link
              isExternal
              href="https://apps.datev.de/help-center/documents/1007329"
              rel="noreferrer"
            >
              link
            </Link>
            text
          </Trans>
        </span>
      </p>
      <ol className={styles.stepsList}>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.datev.preloginModal.step1.text')}
          </span>
        </li>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.datev.preloginModal.step2.text')}
          </span>
          <br />
          <span className={styles.stepInfo}>
            <Trans i18nKey="bookkeep.integrations.datev.preloginModal.step2.info">
              text
              <Link
                isExternal
                href="https://helpcenter.spendesk.com/articles/4291651-set-up-datev-connect-online-permissions-from-unternehmen-online"
                rel="noreferrer"
              >
                link
              </Link>
              text
            </Trans>
          </span>
        </li>
        <li>
          <span className={styles.step}>
            <Trans i18nKey="bookkeep.integrations.datev.preloginModal.step3.text">
              text
              <Link
                isExternal
                href="https://www.datev.de/web/de/datev-shop/betriebliches-rechnungswesen/rechnungsdatenservice-1-0/"
                rel="noreferrer"
              >
                link
              </Link>
              text
            </Trans>
          </span>
          <br />
          <span className={styles.stepInfo}>
            {t('bookkeep.integrations.datev.preloginModal.step3.info')}
          </span>
        </li>
        <li>
          <span className={styles.step}>
            <Trans i18nKey="bookkeep.integrations.datev.preloginModal.step4.text">
              text
              <Link
                isExternal
                href={routeFor(routes.COMPANY_ACCOUNTING_INTEGRATION.path, {
                  company: companyId,
                })}
                rel="noreferrer"
              >
                link
              </Link>
              text
            </Trans>
          </span>
        </li>
      </ol>

      <p className={styles.bottomText}>
        <Button
          className={styles.helpButton}
          component="a"
          iconName="help-circle"
          iconPosition="left"
          href="https://www.terminland.de/datev-partner-onboarding/"
          target="_blank"
          text={t('bookkeep.integrations.datev.preloginModal.help')}
          variant="ghost"
        />
      </p>
    </div>
  );
};

const ChiftModalContent = ({
  isConfirmInitiallyDisabled,
  setIsConfirmInitiallyDisabled,
  integration,
}: {
  isConfirmInitiallyDisabled: boolean;
  setIsConfirmInitiallyDisabled: (isConfirmInitiallyDisabled: boolean) => void;
  integration: AccountingSoftware;
}) => {
  const { t } = useTranslation('global');
  const brandName = getBrandName(t, integration);

  return (
    <div className={styles.infoWrapper}>
      <p className={styles.subtitle}>
        <span>
          {t('bookkeep.integrations.chift.preloginModal.subTitle', {
            integration: brandName,
          })}
        </span>
      </p>
      <ol className={styles.stepsList}>
        <li>
          <span className={styles.step}>
            <Trans
              i18nKey="bookkeep.integrations.chift.preloginModal.step1.textWithLink"
              values={{ integration: brandName }}
            >
              text
              <Link
                isExternal
                href="https://helpcenter.spendesk.com/fr/articles/9521219-1-pre-requis-a-la-mise-en-place-de-l-integration-sage100"
                rel="noreferrer"
              >
                link
              </Link>
              text
            </Trans>
          </span>
        </li>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.chift.preloginModal.step2.text')}
          </span>
        </li>
      </ol>

      <CheckboxField
        className="mt-s hover:bg-[transparent]"
        isChecked={!isConfirmInitiallyDisabled}
        label={
          <Trans
            values={{ integration: brandName }}
            i18nKey="bookkeep.integrations.settings.chiftReadyToConnectConfirmation"
          />
        }
        onChange={() =>
          setIsConfirmInitiallyDisabled(!isConfirmInitiallyDisabled)
        }
      />
    </div>
  );
};

const XeroModalContent = ({
  integration,
  isSwitching,
}: {
  integration: NativeAccountingIntegration;
  isSwitching: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.infoWrapper}>
      <p className={styles.subtitle}>
        <span>
          <Trans
            i18nKey={getNativeAccountingIntegrationDetails(
              integration,
            ).i18nPreloginSubtitle(isSwitching)}
          >
            text
            <Link
              isExternal
              href="https://helpcenter.spendesk.com/articles/5511455-configurer-l-integration-native-de-xero"
              rel="noreferrer"
            >
              link
            </Link>
            text
          </Trans>
        </span>
      </p>
      <p className={classNames(styles.steps, 'title-l')}>
        <span>{t('bookkeep.integrations.xero.preloginModal.steps')}</span>
      </p>
      <ol className={styles.stepsList}>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.xero.preloginModal.step1.text')}
          </span>
        </li>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.xero.preloginModal.step2.text')}
          </span>
        </li>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.xero.preloginModal.step3.text')}
          </span>
        </li>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.xero.preloginModal.step4.text')}
          </span>
        </li>
      </ol>
    </div>
  );
};

const QuickbooksModalContent = ({
  integration,
  isSwitching,
}: {
  integration: NativeAccountingIntegration;
  isSwitching: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.infoWrapper}>
      <p className={styles.subtitle}>
        <span>
          <Trans
            i18nKey={getNativeAccountingIntegrationDetails(
              integration,
            ).i18nPreloginSubtitle(isSwitching)}
          >
            text
            <Link
              isExternal
              href="https://helpcenter.spendesk.com/collections/2383252-integrations-with-accounting-software"
              rel="noreferrer"
            >
              link
            </Link>
            text
          </Trans>
        </span>
      </p>
      <p className={classNames(styles.steps, 'title-l')}>
        <span>{t('bookkeep.integrations.quickbooks.preloginModal.steps')}</span>
      </p>
      <ol className={styles.stepsList}>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.quickbooks.preloginModal.step1.text')}
          </span>
        </li>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.quickbooks.preloginModal.step2.text')}
          </span>
        </li>
        <li>
          <span className={styles.step}>
            {t('bookkeep.integrations.quickbooks.preloginModal.step3.text')}
          </span>
        </li>
      </ol>
    </div>
  );
};

const NetsuiteModalContent = ({
  integration,
  isSwitching,
  tokenSet,
  setTokenSet,
  oAuth1Errors,
}: {
  integration: NativeAccountingIntegration;
  isSwitching: boolean;
  tokenSet: Oauth1TokenSet;
  setTokenSet: (tokenSet: Oauth1TokenSet) => void;
  oAuth1Errors: Oauth1Errors;
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.infoWrapper, styles.netsuiteModal)}>
      <p className={styles.subtitle}>
        <span>
          <Trans
            i18nKey={getNativeAccountingIntegrationDetails(
              integration,
            ).i18nPreloginSubtitle(isSwitching)}
          />
        </span>
      </p>
      <p className={classNames(styles.steps, 'title-l')}>
        <span>{t('bookkeep.integrations.netsuite.preloginModal.steps')}</span>
      </p>
      <ol className={styles.stepsList}>
        <li>
          <p className={styles.step}>
            <Trans i18nKey="bookkeep.integrations.netsuite.preloginModal.step1.title">
              <a
                href="https://helpcenter.spendesk.com/articles/6863688-netsuite-native-integration-connect-your-spendesk-account-to-netsuite"
                target="_blank"
                rel="noopener noreferrer"
              >
                this article
              </a>
            </Trans>
          </p>
          <p className={styles.stepInfo}>
            {t(
              'bookkeep.integrations.netsuite.preloginModal.step1.description',
            )}
          </p>
        </li>
        <li>
          <p className={styles.step}>
            {t('bookkeep.integrations.netsuite.preloginModal.step2.title')}
          </p>
          <div>
            <FormField
              className={styles.formField}
              label={t(
                'bookkeep.integrations.netsuite.preloginModal.step2.form.field1.label',
              )}
              alertMessage={
                oAuth1Errors.accountId
                  ? t(
                      'bookkeep.integrations.netsuite.preloginModal.step2.form.field1.errorMessage',
                    )
                  : ''
              }
            >
              <TextInput
                fit="parent"
                placeholder={t(
                  'bookkeep.integrations.netsuite.preloginModal.step2.form.field1.placeholder',
                )}
                id="netsuite-account-id"
                isInvalid={oAuth1Errors.accountId}
                onChange={(event) =>
                  setTokenSet({
                    ...tokenSet,
                    accountId: event.target.value,
                  })
                }
              />
            </FormField>
            <FormField
              className={styles.formField}
              label={t(
                'bookkeep.integrations.netsuite.preloginModal.step2.form.field2.label',
              )}
              alertMessage={
                oAuth1Errors.consumerId
                  ? t(
                      'bookkeep.integrations.netsuite.preloginModal.step2.form.field2.errorMessage',
                    )
                  : ''
              }
            >
              <TextInput
                fit="parent"
                placeholder={t(
                  'bookkeep.integrations.netsuite.preloginModal.step2.form.field2.placeholder',
                )}
                id="netsuite-client-id"
                isInvalid={oAuth1Errors.consumerId}
                onChange={(event) =>
                  setTokenSet({
                    ...tokenSet,
                    consumerId: event.target.value,
                  })
                }
              />
            </FormField>
            <FormField
              className={styles.formField}
              label={t(
                'bookkeep.integrations.netsuite.preloginModal.step2.form.field3.label',
              )}
              alertMessage={
                oAuth1Errors.consumerSecret
                  ? t(
                      'bookkeep.integrations.netsuite.preloginModal.step2.form.field3.errorMessage',
                    )
                  : ''
              }
            >
              <TextInput
                fit="parent"
                placeholder={t(
                  'bookkeep.integrations.netsuite.preloginModal.step2.form.field3.placeholder',
                )}
                id="netsuite-client-secret"
                isInvalid={oAuth1Errors.consumerSecret}
                onChange={(event) =>
                  setTokenSet({
                    ...tokenSet,
                    consumerSecret: event.target.value,
                  })
                }
              />
            </FormField>
          </div>
        </li>
      </ol>
    </div>
  );
};
