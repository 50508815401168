import { InfoTip, Panel, SkeletonText } from '@dev-spendesk/grapes';

import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { useOrganisationReportingGroupStats } from 'src/core/modules/organisation-reporting/hooks/useOrganisationReportingGroupStats';
import { type ExtendedOrganisationReportingEntity } from 'src/core/modules/organisation-reporting/types';

import { GroupBreakdownBlock } from './GroupBreakdownBlock';

type Props = {
  entityDetails: ExtendedOrganisationReportingEntity[];
  isDataFullyLoaded: boolean;
};

export const EntityListGroupSection = ({
  entityDetails,
  isDataFullyLoaded,
}: Props) => {
  const { t } = useTranslation('global');

  const groupStatsQuery = useOrganisationReportingGroupStats(
    entityDetails,
    isDataFullyLoaded,
  );

  return (
    <div className="flex flex-col gap-s">
      <Panel className="w-full">
        <QuerySuspense
          queryState={groupStatsQuery}
          loading={
            <div className="flex flex-col">
              <div className="text-pretty uppercase text-neutral-dark body-s">
                {t('organisation.reporting.page.group.totalWalletBalance')}
              </div>
              <div>
                <SkeletonText size="xxl" width="180px" />
              </div>
              <div>
                <SkeletonText size="l" />
                <div className="flex flex-row justify-between gap-xs pt-s">
                  {[...Array(4).keys()].map((key, index) => {
                    if (index < 2) {
                      return (
                        <div key={`skeleton-${key}`} className="flex-1">
                          <SkeletonText />
                          <SkeletonText />
                        </div>
                      );
                    }
                    return (
                      <div key={`skeleton-${key}`} className="flex-1">
                        <SkeletonText />
                        <SkeletonText />
                        <SkeletonText />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          }
          fallback={() => null}
        >
          {({
            totalWalletBalance,
            breakdownTotals,
            currencyRates,
            currencyTimestamp,
            mainCurrency,
          }) => (
            <div className="flex flex-col">
              <div className="flex items-center gap-xxs">
                <div className="text-pretty uppercase text-neutral-dark body-s">
                  {t('organisation.reporting.page.group.totalWalletBalance')}
                </div>
                {currencyRates.length > 0 && (
                  <InfoTip
                    className="text-neutral-dark"
                    content={
                      <div className="leading-normal body-s">
                        {t(
                          'organisation.reporting.page.group.currencyRates.tooltip',
                          {
                            rates: formatCurrencyRates(
                              currencyRates,
                              mainCurrency,
                              t,
                            ),
                            date: currencyTimestamp,
                          },
                        )}
                      </div>
                    }
                  />
                )}
              </div>
              <div className="flex flex-col gap-xxs">
                <div className="text-complementary title-xxl">
                  {totalWalletBalance}
                </div>
                <GroupBreakdownBlock
                  currency={mainCurrency}
                  breakdown={breakdownTotals}
                />
              </div>
            </div>
          )}
        </QuerySuspense>
      </Panel>
    </div>
  );
};

const formatCurrencyRates = (
  currencyRates: { sourceCurrency: string; rate: number }[],
  mainCurrency: string,
  t: TGlobalFunctionTyped,
) => {
  if (currencyRates.length <= 2) {
    return currencyRates
      .map((rate) =>
        t('organisation.reporting.page.group.currencyRates.rateFormat', {
          sourceCurrency: rate.sourceCurrency,
          conversionCurrency: mainCurrency,
          rate: rate.rate,
        }),
      )
      .join(` ${t('misc.and')} `);
  }
  return currencyRates
    .map((rate, index) => {
      if (index === currencyRates.length - 1) {
        return `${t('misc.and')} ${t(
          'organisation.reporting.page.group.currencyRates.rateFormat',
          {
            sourceCurrency: rate.sourceCurrency,
            conversionCurrency: mainCurrency,
            rate: rate.rate,
          },
        )}`;
      }
      return t('organisation.reporting.page.group.currencyRates.rateFormat', {
        sourceCurrency: rate.sourceCurrency,
        conversionCurrency: mainCurrency,
        rate: rate.rate,
      });
    })
    .join(', ');
};
