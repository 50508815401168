import { useState } from 'react';

import {
  DEFAULT_ENTITY_FILTERS,
  type EntityFilters,
} from './entity-list-filters/EntityListFilters';
import {
  isExtendedOrganisationReportingEntity,
  type OrganisationReportingEntity,
} from '../../types';

export const FilteredEntityListWrapper = <
  T extends OrganisationReportingEntity,
>({
  entities,
  children,
}: {
  entities: T[];
  children: ({
    filters,
    setFilters,
    filteredEntities,
  }: {
    filters: EntityFilters;
    setFilters: (filters: EntityFilters) => void;
    filteredEntities: T[];
  }) => React.ReactNode;
}) => {
  const [filters, setFilters] = useState<EntityFilters>(DEFAULT_ENTITY_FILTERS);
  const { currencyFilters, entityStatusFilters } = filters;
  const filterByCurrencies = (entity: T) =>
    currencyFilters.length === 0 || currencyFilters.includes(entity.currency);

  const isEntityActive = (entity: T) => {
    const hasExtendedData = isExtendedOrganisationReportingEntity(entity);
    if (!hasExtendedData) {
      return !entity.isChurning && !entity.hasChurned;
    }

    return (
      !entity.isChurning &&
      !entity.hasChurned &&
      !entity.isKycAwaitingApproval &&
      !entity.isKycInProgress
    );
  };
  const filterByEntityStatus = (entity: T) =>
    entityStatusFilters.length === 0 ||
    (entityStatusFilters.includes('active') && isEntityActive(entity)) ||
    (entityStatusFilters.includes('inactive') && !isEntityActive(entity));

  const filterByFundsStatus = (entity: T) => {
    if (filters.fundsStatusFilters.length === 0) {
      return true;
    }

    const hasExtendedData = isExtendedOrganisationReportingEntity(entity);
    if (!hasExtendedData) {
      return true;
    }

    return filters.fundsStatusFilters.some(
      (status) =>
        (status === 'available' && entity.breakdown.shortfall === 0) ||
        (status === 'shortfall' && entity.breakdown.shortfall !== 0),
    );
  };

  return children({
    filters,
    setFilters,
    filteredEntities: entities.filter(
      (entity) =>
        filterByCurrencies(entity) &&
        filterByEntityStatus(entity) &&
        filterByFundsStatus(entity),
    ),
  });
};
