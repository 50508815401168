import type { QueryState } from 'src/core/api/queryState';

import { useOrganisationEntityWalletSummaryQuery } from '../../../hooks/data/useOrganisationEntityWalletSummaryQuery';
import { useOrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import type {
  ExtendedOrganisationReportingEntity,
  OrganisationReportingEntity,
} from '../../../types';
import { OrganisationReportingEntityActions } from '../../OrganisationReportingPage/OrganisationReportingEntityStatistics/OrganisationReportingEntityActions';
import { OrganisationReportingEntityLayout } from '../../OrganisationReportingPage/OrganisationReportingEntityStatistics/OrganisationReportingEntityLayout';
import {
  getEntityStatus,
  OrganisationReportingEntityStatisticsCallout,
} from '../../OrganisationReportingPage/OrganisationReportingEntityStatistics/OrganisationReportingEntityStatisticsCallout/OrganisationReportingEntityStatisticsCallout';
import { OrganisationReportingEntityStatisticsHeader } from '../../OrganisationReportingPage/OrganisationReportingEntityStatistics/OrganisationReportingEntityStatisticsHeader';
import { OrganisationReportingEntityStatisticsWalletSummary } from '../../OrganisationReportingPage/OrganisationReportingEntityStatistics/OrganisationReportingEntityStatisticsWalletSummary';

export type OnDataLoadedResult =
  | {
      entityData: OrganisationReportingEntity;
      status: Extract<QueryState['status'], 'error'>;
    }
  | {
      entityData: ExtendedOrganisationReportingEntity;
      status: Extract<QueryState['status'], 'success'>;
    };

type Props = {
  entity: OrganisationReportingEntity;
  isPinned: boolean;
  togglePinnedEntity: (entityId: string) => void;
  onDataLoaded: (result: OnDataLoadedResult) => void;
};

export const EntityListItem = ({
  entity,
  isPinned,
  togglePinnedEntity,
  onDataLoaded,
}: Props) => {
  const features = useOrganisationFeatures();

  const entityWalletSummaryQueryState = useOrganisationEntityWalletSummaryQuery(
    entity.id,
    { isEnabled: features.wallet },
  );

  const entityStatus = getEntityStatus(entity, entityWalletSummaryQueryState);

  return (
    <>
      <OrganisationReportingEntityLayout entityStatus={entityStatus}>
        <OrganisationReportingEntityStatisticsHeader
          entity={entity}
          canAccessEntity={entityStatus !== 'awaitingKycApproval'}
          isPinned={isPinned}
          togglePinnedEntity={togglePinnedEntity}
        />
        <hr
          className="separator"
          style={{
            borderTop: 'none',
            transform: 'translateX(-24px)',
            width: 'calc(100% + 2 * 24px)',
          }}
        />

        <div className="flex items-stretch gap-[12px]">
          {features.wallet && (
            <OrganisationReportingEntityStatisticsWalletSummary
              entity={entity}
              entityStatus={entityStatus}
              entityWalletSummaryQueryState={entityWalletSummaryQueryState}
              onWalletSummaryLoaded={onDataLoaded}
            />
          )}

          <OrganisationReportingEntityActions
            entity={entity}
            features={features}
            entityStatus={entityStatus}
          />
        </div>
      </OrganisationReportingEntityLayout>

      {entityStatus && (
        <OrganisationReportingEntityStatisticsCallout
          state={entityStatus}
          entityId={entity.id}
        />
      )}
    </>
  );
};
