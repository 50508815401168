import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import { TypeGuardError } from 'src/core/utils/switchGuard';

import { type UsersManagedByKomboHr } from '../../members/hooks/useGetManagedUsersByKomboHrIntegrationQuery';
import { integrationLogo } from '../assets/integration-logos/integration-logo';
import logoSpendesk from '../assets/spendesk.svg';
import {
  komboIntegrations,
  type IntegrationError,
  type IntegrationId,
  type KomboIntegration,
} from '../types';

export const isKomboIntegration = (
  integrationId: IntegrationId,
): integrationId is KomboIntegration =>
  (komboIntegrations as unknown as string[]).includes(integrationId);

export const isKnownIntegration = (integrationId: IntegrationId) => {
  if (isKomboIntegration(integrationId)) {
    return true;
  }

  switch (integrationId) {
    case 'travelperk':
    case 'spendesk-oauth2':
      return true;
    default:
      return false;
  }
};

export const getIntegrationLogo = (integrationId: IntegrationId) => {
  if (isKomboIntegration(integrationId) || integrationId === 'travelperk') {
    return integrationLogo[integrationId];
  }

  switch (integrationId) {
    case 'spendesk-oauth2':
      // TODO@api update logo
      return logoSpendesk;
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationLogo: Unknown integrationId',
      );
  }
};

export const getPartnerIntegrationLogoByName = (name: string) => {
  const logo = Object.keys(integrationLogo).includes(name);

  if (logo) {
    return integrationLogo[name as keyof typeof integrationLogo];
  }

  return logoSpendesk;
};

export const getSpendeskLogo = () => logoSpendesk;

export const getIntegrationName = (
  t: TGlobalFunctionTyped,
  integrationId: IntegrationId,
) => {
  if (isKomboIntegration(integrationId)) {
    return t(`integration.kombo.${integrationId}.name`);
  }

  switch (integrationId) {
    case 'travelperk':
    case 'spendesk-oauth2':
      return t(`integration.${integrationId}.name`);
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationName: Unknown integrationId',
      );
  }
};

export const getIntegrationUrl = (
  t: TGlobalFunctionTyped,
  integrationId: IntegrationId,
) => {
  if (isKomboIntegration(integrationId)) {
    return t(`integration.kombo.${integrationId}.url`);
  }

  switch (integrationId) {
    case 'travelperk':
    case 'spendesk-oauth2':
      return null;
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationName: Unknown integrationId',
      );
  }
};

export const getHelpCenter = (
  t: TGlobalFunctionTyped,
  integrationId: IntegrationId,
) => {
  if (isKomboIntegration(integrationId)) {
    return t(`integration.kombo.${integrationId}.helpCenter`);
  }

  switch (integrationId) {
    case 'travelperk':
    case 'spendesk-oauth2':
      return t(`integration.${integrationId}.helpCenter`);
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationName: Unknown integrationId',
      );
  }
};

export const getIntegrationDescriptionKey = (integrationId: IntegrationId) => {
  if (isKomboIntegration(integrationId)) {
    return `integration.kombo.${integrationId}.description`;
  }

  switch (integrationId) {
    case 'travelperk':
    case 'spendesk-oauth2':
      return `integration.${integrationId}.description`;
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationDescription: Unknown integrationId',
      );
  }
};

export const getIntegrationDetailsDescription = (
  t: TGlobalFunctionTyped,
  integrationId: IntegrationId,
) => {
  if (isKomboIntegration(integrationId)) {
    return t(`integration.kombo.${integrationId}.details.description`);
  }

  switch (integrationId) {
    case 'travelperk':
    case 'spendesk-oauth2':
      return t(`integration.${integrationId}.details.description`);
    default:
      throw new TypeGuardError(
        integrationId,
        'getIntegrationFullDescription: Unknown integrationId',
      );
  }
};

export const getIntegrationErrorTitle = (
  t: TGlobalFunctionTyped,
  integrationError: IntegrationError,
) => {
  switch (integrationError) {
    case 'unknown':
    case 'wrongPlan':
    case 'notConnected':
    case 'pendingSync':
    case 'pendingAction':
    case 'hrIntegrationAlreadyConnected':
      return t(`integration.errors.${integrationError}.title`);
    default:
      throw new TypeGuardError(
        integrationError,
        'getIntegrationErrorTitle: Unknown integrationError',
      );
  }
};

export const getIntegrationErrorDetails = (
  t: TGlobalFunctionTyped,
  integrationError: IntegrationError,
) => {
  switch (integrationError) {
    case 'unknown':
    case 'wrongPlan':
    case 'notConnected':
    case 'pendingSync':
    case 'pendingAction':
    case 'hrIntegrationAlreadyConnected':
      return t(`integration.errors.${integrationError}.details`);
    default:
      throw new TypeGuardError(
        integrationError,
        'getIntegrationErrorDetails: Unknown integrationError',
      );
  }
};

export const canRefreshSettings = (integrationId: IntegrationId) => {
  if (isKomboIntegration(integrationId)) {
    return true;
  }

  switch (integrationId) {
    case 'travelperk':
    case 'spendesk-oauth2':
      return false;
    default:
      throw new TypeGuardError(
        integrationId,
        'canRefreshSettings: Unknown integrationId',
      );
  }
};

export const isIntegrationWithAutomaticallyCollectedReceipt = (
  integrationId: IntegrationId,
) => {
  if (isKomboIntegration(integrationId)) {
    return false;
  }

  switch (integrationId) {
    case 'travelperk':
      return true;
    case 'spendesk-oauth2':
      return false;
    default:
      throw new TypeGuardError(
        integrationId,
        'isIntegrationWithAutomaticallyCollectedReceipt: Unknown integrationId',
      );
  }
};

export type MemberIntegrationMetadata =
  | {
      existsInKombo: true;
      iconName: 'warning' | 'success';
      iconVariant: 'warning' | 'success';
      tooltipText: string;
    }
  | { existsInKombo: false };

export const classifyManagedEmployees = ({
  memberId,
  usersManagedByIntegration,
}: {
  memberId: string;
  usersManagedByIntegration: UsersManagedByKomboHr | undefined;
}) => {
  if (
    !usersManagedByIntegration ||
    !usersManagedByIntegration.managedUsers.has(memberId)
  ) {
    return 'notManaged';
  }

  return usersManagedByIntegration.managedUsers.get(memberId)
    ? 'onSync'
    : 'outOfSync';
};

export const memberIntegrationMetadata = ({
  memberId,
  usersManagedByIntegration,
  t,
}: {
  memberId: string;
  usersManagedByIntegration: UsersManagedByKomboHr | undefined;
  t: TGlobalFunctionTyped;
}): MemberIntegrationMetadata => {
  if (
    usersManagedByIntegration &&
    usersManagedByIntegration.managedUsers.has(memberId)
  ) {
    const activelyManaged =
      usersManagedByIntegration.managedUsers.get(memberId);
    return {
      existsInKombo: true,
      tooltipText: activelyManaged
        ? t('integration.members.tooltipOnSync')
        : t('integration.members.tooltipOutOfSync'),
      iconName: activelyManaged ? ('success' as const) : ('warning' as const),
      iconVariant: activelyManaged
        ? ('success' as const)
        : ('warning' as const),
    };
  }

  return {
    existsInKombo: false,
  };
};
