import {
  Callout,
  IconButton,
  SkeletonTable,
  SkeletonText,
  Table,
} from '@dev-spendesk/grapes';
import { useState, type Dispatch } from 'react';

import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';
import { useIntegrationStatusQuery } from 'src/core/modules/bookkeep/hooks';

import { BankJournalSelectField } from './BankJournalSelectField';
import { JournalSelectionModal } from './JournalSelectionModal';
import { type JournalModalState } from './journalModalState';
import { type Journal, type JournalMapping } from '../../../../../accounting';
import { useCreateJournalMappingMutation } from '../../../../hooks/useCreateJournalMappingMutation';
import { useJournalsQuery } from '../../../../hooks/useJournalsQuery';
import { CellWithButton } from '../../components/CellWithIconButton/CellWithIconButton';

export const JournalsSection = () => {
  const [modalState, setModalState] = useState<JournalModalState>({
    modalKind: 'closed',
  });

  const accountingIntegrationQueryResult = useIntegrationStatusQuery();

  const queriesState = useJournalsQuery();
  const { t } = useTranslation();
  const { pushNotif } = useNotifications();

  const [createJournalMapping] = useCreateJournalMappingMutation();

  async function handleCreateJournalMapping(journalMapping: JournalMapping) {
    if (!journalMapping.mappedJournal) {
      return;
    }
    await createJournalMapping({
      type: journalMapping.mappingType,
      journalId: journalMapping.mappedJournal.id,
    });

    setModalState({ modalKind: 'closed' });
    pushNotif({
      type: NotificationType.Success,
      message: t(
        `bookkeep.integrations.settings.chift.${
          journalMapping.mappingType === 'bank_journal'
            ? 'bankJournalExport'
            : 'purchaseJournalExport'
        }.journalSuccessfulUpdate`,
      ),
    });
  }

  return (
    <div>
      <div>
        <h5 className="mb-xs text-neutral-darker body-m">
          {t(
            'bookkeep.integrations.settings.chift.purchaseJournalExport.title',
          )}
        </h5>
        <p className="text-neutral-dark body-m">
          {t(
            'bookkeep.integrations.settings.chift.purchaseJournalExport.description',
          )}
        </p>
      </div>

      <QuerySuspense
        queryState={accountingIntegrationQueryResult}
        fallback={() => null}
        loading={<div />}
      >
        {(accountingIntegration) => (
          <QuerySuspense
            queryState={queriesState}
            loading={<JournalsSkeletonTable />}
            fallback={() => <JournalsErrorWarning />}
          >
            {(data) => {
              return (
                <>
                  <Table<JournalMapping>
                    rowHeight="compact"
                    hasColumnSeparator
                    columns={[
                      {
                        id: 'purchase-journal-name',
                        header: t(
                          'bookkeep.integrations.settings.chift.purchaseJournalExport.purchaseJournal',
                        ),
                        width: '50%',
                        renderCell(row) {
                          return t(
                            `bookkeep.integrations.settings.chift.purchaseJournalExport.purchaseJournalTypeName.${row.mappingType}`,
                          );
                        },
                      },
                      {
                        id: 'sage-journal-name',
                        header: t(
                          'bookkeep.integrations.settings.chift.purchaseJournalExport.chiftJournal',
                          { integration: accountingIntegration.integration },
                        ),
                        width: '50%',
                        renderCell(row, { isRowHovered }) {
                          return (
                            <CellWithButton
                              isButtonVisible={isRowHovered}
                              button={
                                <TableButton
                                  mapping={row}
                                  setModalState={setModalState}
                                />
                              }
                            >
                              {row.mappedJournal ? (
                                `${row.mappedJournal.externalCode} - ${row.mappedJournal.externalName}`
                              ) : (
                                <em>{t('misc.none')}</em>
                              )}
                            </CellWithButton>
                          );
                        },
                      },
                    ]}
                    data={data.mappings.filter(
                      (mapping) => mapping.mappingType !== 'bank_journal',
                    )}
                    footer={
                      data.availableJournals.length === 0 ? (
                        <TableFooter journals={data.availableJournals} />
                      ) : undefined
                    }
                  />

                  {(accountingIntegration.integration === 'Sage100' ||
                    accountingIntegration.integration === 'ACD' ||
                    accountingIntegration.integration === 'Odoo' ||
                    accountingIntegration.integration === 'ExactOnlineFr') &&
                    (accountingIntegration.settingsValidation.journalCard ||
                      accountingIntegration.settingsValidation
                        .journalExpenseClaims ||
                      accountingIntegration.settingsValidation
                        .journalInvoicePayables) && (
                      <div className="mt-xs">
                        <Callout
                          title={t(
                            'bookkeep.integrations.settings.chift.purchaseJournalExport.chiftNotAllMapped',
                            {
                              integration: accountingIntegration.integration,
                            },
                          )}
                          variant="warning"
                        />
                      </div>
                    )}

                  <JournalSelectionModal
                    modalState={modalState}
                    setModalState={setModalState}
                    availableJournals={data.availableJournals}
                    initialJournal={
                      modalState.modalKind === 'mapped'
                        ? data.mappings.find(
                            (journalMapping) =>
                              journalMapping.mappingType ===
                              modalState.mappingType,
                          )?.mappedJournal
                        : undefined
                    }
                    onCreate={(journalMapping: JournalMapping) =>
                      handleCreateJournalMapping(journalMapping)
                    }
                  />
                  <BankJournalSelectField
                    availableJournals={data.availableJournals}
                    initialJournal={
                      data.mappings.find(
                        (mapping) => mapping.mappingType === 'bank_journal',
                      )?.mappedJournal
                    }
                    onCreate={(journalMapping: JournalMapping) =>
                      handleCreateJournalMapping(journalMapping)
                    }
                  />
                  {accountingIntegration.integration === 'Sage100' &&
                    accountingIntegration.settingsValidation.journalBank && (
                      <div className="mt-xs">
                        <Callout
                          title={t(
                            'bookkeep.integrations.settings.chift.bankJournalExport.notMapped',
                          )}
                          variant="warning"
                        />
                      </div>
                    )}
                </>
              );
            }}
          </QuerySuspense>
        )}
      </QuerySuspense>
      <div className="separator my-m" />
    </div>
  );
};

const TableFooter = ({ journals }: { journals: Journal[] }) => {
  const { t } = useTranslation();

  if (journals.length === 0) {
    return (
      <em>
        {t(
          'bookkeep.integrations.settings.chift.purchaseJournalExport.noJournals',
        )}
      </em>
    );
  }

  return null;
};

interface TableButtonProps {
  mapping: JournalMapping;
  setModalState: Dispatch<JournalModalState>;
}

const TableButton = ({ mapping, setModalState }: TableButtonProps) => {
  const { t } = useTranslation('global');
  return (
    <span className="CellWithButton__button">
      <IconButton
        iconName="pen"
        variant="borderless"
        onClick={() => {
          setModalState({
            modalKind: mapping.mappedJournal ? 'mapped' : 'map',
            ...mapping,
          });
        }}
        aria-label={t('misc.edit')}
      />
    </span>
  );
};

const JournalsSkeletonTable = () => (
  <SkeletonTable
    columns={[
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '50%',
      },
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '50%',
      },
    ]}
    withColumnSeparator
    withHeader
    numberOfRows={2}
  />
);

const JournalsErrorWarning = () => {
  const { t } = useTranslation();

  return (
    <Callout
      title={t(
        'bookkeep.integrations.settings.chift.purchaseJournalExport.errorLoading',
      )}
      variant="alert"
    />
  );
};
