import { type ThunkDispatch } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  type MFAAction,
  requestMfaProcedureSuccess,
} from 'common/components/ModalMFA/actions';
import {
  requestScaProcedureSuccess,
  type SCAAction,
} from 'modules/StrongCustomerAuthentication/redux/actions';
import { addNotification, NotificationType } from 'modules/app/notifications';
import { type ExportMethod, PaymentMethod } from 'modules/company';
import { companyAPI, baseAPI } from 'src/core/api/axios';
import { type I18nKey } from 'src/core/common/hooks/useTranslation';
import i18n from 'src/core/config/i18n';
import { type AppState } from 'src/core/reducers';
import { getIsExpenseMigratedToTransferScheduling } from 'src/core/selectors/globalSelectors';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { getFilenameFromContentDispositionHeader } from 'src/core/utils/contentDisposition';
import { downloadFromBlob } from 'src/core/utils/fileDownloader';

import { type ExpenseClaimsActions } from './actionTypes';
import * as expenseClaimsActions from './actions';
import * as expenseClaimsSelectors from './selectors';
import { type GBP_BATCH_CONFIRMATION_NOT_ALLOWED_REASON } from '../../invoices/transfer/hooks/api/useConfirmTransfers';
import {
  scheduledPaymentsBatchWithScheduledPaymentsToScheduledPaymentsBatch,
  buildSchedulePaymentsPayload,
} from '../models';
import {
  ScheduledPaymentsBatchStatus,
  type PaymentInfoStatus,
  type ScheduledPaymentsBatchWithScheduledPayments,
} from '../types';
import { formatExecutionDate } from '../utils';

export const fetchExpenseClaimsCounts =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    // This isn't proper request cancellation but a simple way to avoid serial
    // fetches of the same data.
    if (expenseClaimsSelectors.getIsExpenseClaimsCountsLoading(state)) {
      return;
    }

    dispatch(expenseClaimsActions.fetchExpenseClaimsCountsRequest());

    let counts;
    try {
      const isExpenseMigratedToTransferScheduling =
        getIsExpenseMigratedToTransferScheduling(state);
      const url = isExpenseMigratedToTransferScheduling
        ? '/transfer-scheduling/counts?type=expense'
        : '/scheduled_payments/expense-claim/counts';
      const res = await companyAPI.get(url, { companyId });
      counts = res.data;
    } catch (error) {
      dispatch(expenseClaimsActions.fetchExpenseClaimsCountsFailure());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('expenseClaims.fetchCountsError'),
        }),
      );

      throw error;
    }

    dispatch(expenseClaimsActions.fetchExpenseClaimsCountsSuccess(counts));
  };

export const fetchUsersScheduledPayments = () => {
  return async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const isExpenseMigratedToTransferScheduling =
      getIsExpenseMigratedToTransferScheduling(state);
    const offset =
      expenseClaimsSelectors.getUsersScheduledPaymentsPaginationOffset(state);
    const limit =
      expenseClaimsSelectors.getUsersScheduledPaymentsPaginationLimit(state);
    const companyId = getCompanyId(state);

    dispatch(expenseClaimsActions.fetchUsersScheduledPaymentsRequest());

    let data;
    try {
      const res = await companyAPI.get(
        isExpenseMigratedToTransferScheduling
          ? `/transfer-scheduling/payments_to_schedule?type=expense&limit=${limit}&offset=${offset}`
          : `/scheduled_payments/expense-claim?groupByRecipient=true&status=draft&limit=${limit}&offset=${offset}`,
        { companyId },
      );
      data = res.data;
    } catch (error) {
      dispatch(expenseClaimsActions.fetchUsersScheduledPaymentsFailure());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'expenseClaims.pay.fetchScheduledPaymentsByUsersError',
          ),
        }),
      );

      throw error;
    }

    dispatch(
      expenseClaimsActions.fetchUsersScheduledPaymentsSuccess({
        totalCount: data.total,
        items: data.scheduledPaymentsByUser,
      }),
    );

    // TODO: ideally we shouldn't wait for the list fetching to resolve before
    // fetching the counters. We will be able to leverage `Promise.allSettled()`
    // for that soon.
    dispatch(fetchExpenseClaimsCounts());
  };
};

export const fetchUserScheduledPayments = (userId: string) => {
  return async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const companyId = getCompanyId(getState());
    const isExpenseMigratedToTransferScheduling =
      getIsExpenseMigratedToTransferScheduling(getState());

    dispatch(expenseClaimsActions.fetchUserScheduledPaymentsRequest());

    try {
      const { data } = await companyAPI.get(
        isExpenseMigratedToTransferScheduling
          ? `/transfer-scheduling/payments_to_schedule?type=expense&counterpartyIds[]=${userId}`
          : `/scheduled_payments/expense-claim?groupByRecipient=true&status=draft&recipient=${userId}`,
        { companyId },
      );

      dispatch(
        expenseClaimsActions.fetchUserScheduledPaymentsSuccess(
          data.scheduledPaymentsByUser[0],
        ),
      );
    } catch (error) {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'expenseClaims.pay.fetchScheduledPaymentsByUserError',
          ),
        }),
      );

      throw error;
    }
  };
};

export const sendMissingBankInfoReminders = () => {
  return async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);
    const userIds = expenseClaimsSelectors.getSelectedUsersWithAlertIds(state);

    dispatch(expenseClaimsActions.sendMissingBankInfoRemindersRequest());
    try {
      await companyAPI.post(
        '/users/bank-info/reminders',
        {
          userIds,
        },
        { companyId },
      );

      dispatch(expenseClaimsActions.sendMissingBankInfoRemindersSuccess());
      dispatch(
        addNotification({
          type: NotificationType.Success,
          message: i18n.t(
            'expenseClaims.pay.sendMissingBankInfoRemindersSuccess',
          ),
        }),
      );
    } catch (error) {
      dispatch(expenseClaimsActions.sendMissingBankInfoRemindersFailure());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'expenseClaims.pay.sendMissingBankInfoRemindersError',
          ),
        }),
      );

      throw error;
    }
  };
};

export const downloadCsv = () => {
  return async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const scheduledPaymentIds =
      expenseClaimsSelectors.getSelectedScheduledPaymentWithAlertIds(state);
    const companyId = getCompanyId(state);
    const scheduledPayments =
      expenseClaimsSelectors.getSelectedScheduledPaymentsWithAlert(state);
    const isExpenseMigratedToTransferScheduling =
      getIsExpenseMigratedToTransferScheduling(state);

    dispatch(expenseClaimsActions.downloadCsvRequest());
    try {
      let response;
      if (isExpenseMigratedToTransferScheduling) {
        response = await companyAPI.post(
          'transfer-scheduling/schedule_payments',
          buildSchedulePaymentsPayload({
            scheduledPayments,
            executionDate: new Date(),
            paymentMethod: PaymentMethod.Csv,
          }),
          { companyId },
        );
      } else {
        response = await companyAPI.post(
          'scheduled_payments/expense-claim/send-to-payment',
          {
            paymentMethod: PaymentMethod.Csv,
            executionDate: formatExecutionDate(new Date()),
            scheduledPaymentsIds: scheduledPaymentIds,
          },
          { companyId },
        );
      }
      const { data } = response;
      await downloadFile(data.nextUrl);

      dispatch(expenseClaimsActions.resetUsersScheduledPayments());
      dispatch(fetchUsersScheduledPayments());

      dispatch(expenseClaimsActions.downloadCsvSuccess());
      dispatch(
        addNotification({
          type: NotificationType.Success,
          message: i18n.t('expenseClaims.pay.downloadCsvSuccess'),
        }),
      );
    } catch (error) {
      dispatch(expenseClaimsActions.downloadCsvFailure());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('expenseClaims.pay.downloadCsvError'),
        }),
      );

      throw error;
    }
  };
};

export const refreshUsersScheduledPayments = () => {
  return (dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>) => {
    dispatch(expenseClaimsActions.resetUsersScheduledPayments());
    dispatch(fetchUsersScheduledPayments());
  };
};

/** @knipignore */
export type SendToPaymentError = {
  reason: typeof GBP_BATCH_CONFIRMATION_NOT_ALLOWED_REASON;
  errors: {
    wtRequestId: string;
    wtRequest: {
      amount: number;
      currency: string;
      beneficiary: {
        supplierName: string;
      };
    };
    scheduledPaymentId: string;
    error: string;
  }[];
};

type SendToPaymentStatus = {
  status: number;
};

export type SendToPaymentErrorResponse = {
  response?: {
    data?: SendToPaymentError | SendToPaymentStatus;
  };
};

export const sendToPayment = (
  executionDate: Date,
  paymentMethod: PaymentMethod,
  options?: {
    onError?: (error: SendToPaymentErrorResponse) => boolean;
    onSuccess?: () => void;
  },
) => {
  return async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions | MFAAction>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const scheduledPayments =
      expenseClaimsSelectors.getSelectedScheduledPaymentsWithoutAlert(state);
    const scheduledPaymentIds =
      expenseClaimsSelectors.getSelectedScheduledPaymentWithoutAlertIds(state);
    const companyId = getCompanyId(state);
    const isExpenseMigratedToTransferScheduling =
      getIsExpenseMigratedToTransferScheduling(state);

    dispatch(expenseClaimsActions.sendToPaymentRequest());
    try {
      let response;
      if (isExpenseMigratedToTransferScheduling) {
        response = await companyAPI.post(
          'transfer-scheduling/schedule_payments',
          buildSchedulePaymentsPayload({
            scheduledPayments,
            executionDate,
            paymentMethod,
          }),
          { companyId },
        );
      } else {
        response = await companyAPI.post(
          'scheduled_payments/expense-claim/send-to-payment',
          {
            paymentMethod,
            executionDate: formatExecutionDate(executionDate),
            scheduledPaymentsIds: scheduledPaymentIds,
          },
          { companyId },
        );
      }

      const { data } = response;

      if (data.urlType === 'file') {
        await downloadFile(
          data.nextUrl,
          paymentMethod === PaymentMethod.XmlSepa,
        );
      } else if (data.urlType === 'procedure') {
        const { data: mfaProcedure } = await baseAPI.get(data.nextUrl);
        dispatch(requestMfaProcedureSuccess(mfaProcedure));
      }

      if (
        paymentMethod === PaymentMethod.Csv ||
        paymentMethod === PaymentMethod.XmlSepa
      ) {
        dispatch(
          addNotification({
            type: NotificationType.Success,
            message: i18n.t('expenseClaims.pay.sendToPaymentSuccess'),
          }),
        );
      }

      dispatch(expenseClaimsActions.sendToPaymentSuccess());

      if (
        data.skippedScheduledPaymentIds &&
        data.skippedScheduledPaymentIds.length > 0
      ) {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18n.t(
              'submitMyInvoice.scheduled.wireTransfer.sentToPaymentModalPaymentsSkipped',
            ),
          }),
        );
      }

      track(AnalyticEventName.FINANCE_SCHEDULE_PAYMENT_SCHEDULING_VALIDATED, {
        category: 'expense_claim',
        paymentMethod,
        executionDate: 'custom_due_dates',
      });

      if (options?.onSuccess) {
        options.onSuccess();
      }
    } catch (error) {
      handleSendToPaymentError(error, options, dispatch);
    }
  };
};

const handleSendToPaymentError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  options: Parameters<typeof sendToPayment>[2],
  dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions | MFAAction>,
) => {
  dispatch(expenseClaimsActions.sendToPaymentFailure());

  if (options?.onError) {
    const isErrorHandled = options.onError(error);

    if (isErrorHandled) {
      return;
    }
  }

  // If status = 409, there are ongoing mfa procedures
  if (error.response?.data?.status === 409) {
    dispatch(
      addNotification({
        type: NotificationType.Danger,
        message: i18n.t(
          'submitMyInvoices.scheduled.wireTransfer.cancelMfaProceduresError',
        ),
      }),
    );
  } else {
    dispatch(
      addNotification({
        type: NotificationType.Danger,
        message: i18n.t('expenseClaims.pay.sendToPaymentError'),
      }),
    );
  }

  throw error;
};

const downloadFile = async (fileUrl: string, isXml: boolean = false) => {
  const { data } = await baseAPI.get(fileUrl, {
    responseType: 'blob',
  });

  const fileName = `spendesk_expense-claims-to-reimburse.${
    isXml ? 'xml' : 'csv'
  }`;
  downloadFromBlob(data, fileName);
};

type FetchScheduledPaymentsBatchesFilters = {
  statusFilter?: ScheduledPaymentsBatchStatus[];
};

export const fetchScheduledPaymentsBatches =
  ({ statusFilter }: FetchScheduledPaymentsBatchesFilters) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);
    const cursor =
      expenseClaimsSelectors.getScheduledPaymentsBatchesPaginationNextCursor(
        state,
      );
    const limit =
      expenseClaimsSelectors.getScheduledPaymentsBatchesPaginationLimit(state);
    const currentCancelToken =
      expenseClaimsSelectors.getScheduledPaymentsBatchesCancelToken(state);
    const isFirstLoad = !cursor;

    if (currentCancelToken) {
      currentCancelToken.cancel('Operation overridden');
    }

    const nextCancelToken = axios.CancelToken.source();

    dispatch(
      expenseClaimsActions.fetchScheduledPaymentsBatchesRequest({
        cancelToken: nextCancelToken,
      }),
    );

    let data;
    try {
      const res = await companyAPI.get('/scheduled_payments_batches', {
        params: {
          limit,
          cursor,
          havingScheduledPaymentWithStatus: statusFilter,
        },
        companyId,
        cancelToken: nextCancelToken.token,
      });
      data = res.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      dispatch(expenseClaimsActions.fetchScheduledPaymentsBatchesFailure());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'expenseClaims.history.fetchMonthlyScheduledPaymentsBatchesError',
          ),
        }),
      );

      throw error;
    }

    dispatch(
      expenseClaimsActions.fetchScheduledPaymentsBatchesSuccess({
        items: data.scheduledPaymentsBatches,
        nextCursor: data.nextCursor,
      }),
    );

    if (isFirstLoad) {
      dispatch(fetchExpenseClaimsCounts());
    }
  };

export const resetScheduledPaymentsBatches =
  () =>
  (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const currentCancelToken =
      expenseClaimsSelectors.getScheduledPaymentsBatchesCancelToken(getState());
    if (currentCancelToken) {
      currentCancelToken.cancel('Operation aborted');
    }
    dispatch(expenseClaimsActions.clearScheduledPaymentsBatches());
  };

export type RefreshScheduledPaymentsBatchFilters = {
  status?: ScheduledPaymentsBatchStatus[];
};

// This will extend the paginated reducer to allow refreshing only one item
// from the monthly batches (either update it and its scheduled payments
// children) or remove it if it's gone
// FIXME: revamp it once we handle the scheduled payments in a single array
export const refreshOneScheduledPaymentsBatch =
  (batchId: string, filters?: RefreshScheduledPaymentsBatchFilters) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);
    const scheduledPaymentsCount =
      expenseClaimsSelectors.getScheduledPaymentsBatchScheduledPaymentsCountById(
        state,
        batchId,
      );
    let scheduledPaymentsBatch;
    let monthlyScheduledPaymentsBatch;

    dispatch(expenseClaimsActions.refreshOneScheduledPaymentsBatchRequest());

    try {
      const res = await companyAPI.get<{
        scheduledPaymentsBatch: ScheduledPaymentsBatchWithScheduledPayments;
      }>(`/scheduled_payments/batches/${batchId}`, {
        companyId,
        params: filters && {
          havingStatus: filters.status,
        },
      });
      scheduledPaymentsBatch = res.data.scheduledPaymentsBatch;

      monthlyScheduledPaymentsBatch =
        scheduledPaymentsBatchWithScheduledPaymentsToScheduledPaymentsBatch(
          scheduledPaymentsBatch,
        );
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // If the batch is not found, remove it from the paginated items
        dispatch(
          expenseClaimsActions.refreshOneScheduledPaymentsBatchRemove(batchId),
        );
        // Updates the counters
        dispatch(
          expenseClaimsActions.updateExpenseClaimsCounts({
            expensesToValidate: 0,
            paymentsToConfirm: -scheduledPaymentsCount,
            usersToPay: 0,
          }),
        );

        return;
      }

      dispatch(expenseClaimsActions.refreshOneScheduledPaymentsBatchFailure());
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'expenseClaims.history.refreshOneScheduledPaymentsBatchError',
          ),
        }),
      );

      throw error;
    }

    // Batch is found but its status does not match our filter anymore
    if (
      filters &&
      filters.status &&
      !filters.status.includes(monthlyScheduledPaymentsBatch.status)
    ) {
      dispatch(
        expenseClaimsActions.refreshOneScheduledPaymentsBatchRemove(
          monthlyScheduledPaymentsBatch.id,
        ),
      );

      // Updates the counters
      dispatch(
        expenseClaimsActions.updateExpenseClaimsCounts({
          expensesToValidate: 0,
          paymentsToConfirm: -scheduledPaymentsCount,
          usersToPay: 0,
        }),
      );

      return;
    }

    dispatch(
      expenseClaimsActions.refreshOneScheduledPaymentsBatchUpdate(
        monthlyScheduledPaymentsBatch,
      ),
    );

    const previousCount = scheduledPaymentsCount || 0;
    const nextCount = monthlyScheduledPaymentsBatch.scheduledPaymentsCount;
    const countUpdate = nextCount - previousCount;

    // Updates the counters
    dispatch(
      expenseClaimsActions.updateExpenseClaimsCounts({
        expensesToValidate: 0,
        paymentsToConfirm: countUpdate,
        usersToPay: 0,
      }),
    );

    dispatch(
      expenseClaimsActions.fetchScheduledPaymentsByBatchIdSuccess({
        batchId: scheduledPaymentsBatch.id,
        scheduledPayments: scheduledPaymentsBatch.scheduledPayments,
      }),
    );
  };

export const fetchScheduledPaymentsByBatchId =
  (batchId: string, statusFilter?: PaymentInfoStatus[]) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(
      expenseClaimsActions.fetchScheduledPaymentsByBatchIdRequest({
        batchId,
      }),
    );

    try {
      const { data } = await companyAPI.get(
        `/scheduled_payments/batches/${batchId}`,
        {
          params: { havingStatus: statusFilter },
          companyId,
        },
      );

      dispatch(
        expenseClaimsActions.fetchScheduledPaymentsByBatchIdSuccess({
          batchId,
          scheduledPayments: data.scheduledPaymentsBatch.scheduledPayments,
        }),
      );
    } catch (error) {
      dispatch(
        expenseClaimsActions.fetchScheduledPaymentsByBatchIdFailure({
          batchId,
        }),
      );
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'expenseClaims.history.fetchScheduledPaymentsBatchDetailsError',
          ),
        }),
      );

      throw error;
    }
  };

export const unselectUsersWithAlert =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const usersWithAlertIds =
      expenseClaimsSelectors.getSelectedUsersWithAlertIds(state);
    usersWithAlertIds.forEach((userId) =>
      dispatch(
        expenseClaimsActions.toggleUserScheduledPaymentsSelection(userId),
      ),
    );
  };

export const fetchScheduledPayment =
  (scheduledPaymentId: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(
      expenseClaimsActions.fetchScheduledPaymentRequest({
        scheduledPaymentId,
      }),
    );

    try {
      const { data } = await companyAPI.get(
        `/scheduled_payments/${scheduledPaymentId}/expense-claim`,
        {
          companyId,
        },
      );

      dispatch(expenseClaimsActions.fetchScheduledPaymentSuccess(data));
    } catch (error) {
      dispatch(
        expenseClaimsActions.fetchScheduledPaymentFailure({
          scheduledPaymentId,
        }),
      );
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('expenseClaims.history.fetchScheduledPaymentError'),
        }),
      );

      throw error;
    }
  };

export const redownloadScheduledPaymentsBatchTransferFile =
  (batchId: string, exportMethod: ExportMethod) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    try {
      const { headers, data } = await companyAPI.post(
        `/scheduled_payments/expense-claim/batch/${batchId}/export`,
        { exportMethod },
        { companyId, responseType: 'blob' },
      );

      downloadFromBlob(
        data,
        getFilenameFromContentDispositionHeader(headers['content-disposition']),
      );
      dispatch(
        addNotification({
          type: NotificationType.Success,
          message: i18n.t(
            'expenseClaims.history.redownloadTransferFileSuccess',
          ),
        }),
      );
    } catch {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('expenseClaims.history.redownloadTransferFileError'),
        }),
      );
    }
  };

export const redownloadScheduledPaymentTransferFile =
  (scheduledPaymentId: string, exportMethod: ExportMethod) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    try {
      const { headers, data } = await companyAPI.post(
        `/scheduled_payments/${scheduledPaymentId}/expense-claim/export`,
        { exportMethod },
        { companyId, responseType: 'blob' },
      );

      downloadFromBlob(
        data,
        getFilenameFromContentDispositionHeader(headers['content-disposition']),
      );
      dispatch(
        addNotification({
          type: NotificationType.Success,
          message: i18n.t(
            'expenseClaims.history.redownloadTransferFileSuccess',
          ),
        }),
      );
    } catch {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('expenseClaims.history.redownloadTransferFileError'),
        }),
      );
    }
  };

export const confirmScheduledPaymentsBatch =
  (batchId: string, displayErrorToast = true) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      null,
      ExpenseClaimsActions | MFAAction | SCAAction
    >,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(expenseClaimsActions.confirmScheduledPaymentsBatchRequest());

    let procedureId;
    let factorId;
    let procedureType;
    try {
      const { data } = await companyAPI.post(
        `/scheduled_payments_batches/${batchId}/confirmation`,
        {},
        { companyId },
      );

      procedureId = data.procedureId;
      factorId = data.factorId;
      procedureType = data.authType;
    } catch (error) {
      dispatch(expenseClaimsActions.confirmScheduledPaymentsBatchFailure());

      const errorMessage = getConfirmErrorMessage(
        error.response?.data?.reason,
        true,
      );
      if (errorMessage && displayErrorToast) {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18n.t(errorMessage),
          }),
        );
      }

      throw error;
    }

    if (procedureType === 'mfa') {
      dispatch(
        requestMfaProcedureSuccess({
          id: procedureId,
          factorId,
        }),
      );
    }
    if (procedureType === 'sca') {
      dispatch(
        requestScaProcedureSuccess({
          procedureId,
        }),
      );
    }

    dispatch(
      expenseClaimsActions.confirmScheduledPaymentsBatchSuccess({
        authType: procedureType,
      }),
    );
  };

export const resetScheduledPaymentConfirmationAuthType = () => {
  return (dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>) => {
    dispatch(expenseClaimsActions.resetScheduledPaymentConfirmationAuthType());
  };
};

export const confirmScheduledPayment =
  (scheduledPaymentId: string, displayErrorToast = true) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      null,
      ExpenseClaimsActions | MFAAction | SCAAction
    >,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(expenseClaimsActions.confirmScheduledPaymentRequest());

    let procedureId;
    let factorId;
    let procedureType;
    try {
      const { data } = await companyAPI.post(
        `/scheduled_payments/${scheduledPaymentId}/confirmation`,
        {},
        { companyId },
      );

      procedureId = data.procedureId;
      factorId = data.factorId;
      procedureType = data.authType;
    } catch (error) {
      dispatch(expenseClaimsActions.confirmScheduledPaymentFailure());

      const errorMessage = getConfirmErrorMessage(
        error.response?.data?.reason,
        false,
      );
      if (errorMessage && displayErrorToast) {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18n.t(errorMessage),
          }),
        );
      }
      throw error;
    }

    if (procedureType === 'mfa') {
      dispatch(
        requestMfaProcedureSuccess({
          id: procedureId,
          factorId,
        }),
      );
    }
    if (procedureType === 'sca') {
      dispatch(
        requestScaProcedureSuccess({
          procedureId,
        }),
      );
    }

    dispatch(
      expenseClaimsActions.confirmScheduledPaymentSuccess({
        authType: procedureType,
      }),
    );
  };

type ConfirmErrorReason =
  | 'unableToCreateScaProcedure'
  | 'accountOwnerIsMissingMfaPhoneFactor'
  | 'missingBankInformation'
  | 'notScreened'
  | 'screeningPending'
  | 'screeningBlocked';
const getConfirmErrorMessage = (
  error: ConfirmErrorReason | undefined,
  isBatch: boolean,
): I18nKey | undefined => {
  switch (error) {
    case 'accountOwnerIsMissingMfaPhoneFactor':
      return 'expenseClaims.confirm.accountOwnerPhoneFactorMissing';

    case 'missingBankInformation':
      return 'expenseClaims.confirm.missingBankInformation';

    case 'notScreened':
    case 'screeningPending':
    case 'screeningBlocked':
      return 'expenseClaims.confirm.userNotScreenedError';

    // In the case of a SCA procedure, we don't want to display an error as we automatically fallback on MFA
    case 'unableToCreateScaProcedure':
      return undefined;

    default:
      return isBatch
        ? 'expenseClaims.confirm.confirmScheduledPaymentsBatchError'
        : 'expenseClaims.confirm.confirmScheduledPaymentError';
  }
};

export const cancelScheduledPayment =
  (scheduledPaymentId: string, scheduledPaymentsBatchId: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(expenseClaimsActions.cancelScheduledPaymentRequest());

    try {
      await companyAPI.post(
        `/scheduled_payments/${scheduledPaymentId}/cancellation`,
        null,
        { companyId },
      );
    } catch (error) {
      dispatch(expenseClaimsActions.cancelScheduledPaymentFailure());

      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'expenseClaims.history.cancelScheduledPayment.cancelScheduledPaymentError',
          ),
        }),
      );

      throw error;
    }

    dispatch(
      refreshOneScheduledPaymentsBatch(scheduledPaymentsBatchId, {
        status: [ScheduledPaymentsBatchStatus.Pending],
      }),
    );

    dispatch(fetchExpenseClaimsCounts());

    dispatch(expenseClaimsActions.cancelScheduledPaymentSuccess());
  };

export const cancelScheduledPaymentsBatch =
  (scheduledPaymentsBatchId: string, scheduledPaymentsInBatchCount: number) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);

    dispatch(expenseClaimsActions.cancelScheduledPaymentsBatchRequest());

    try {
      await companyAPI.post(
        `/scheduled_payments_batches/${scheduledPaymentsBatchId}/cancellation`,
        {},
        {
          companyId,
        },
      );
    } catch (error) {
      dispatch(expenseClaimsActions.cancelScheduledPaymentsBatchFailure());

      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'expenseClaims.confirm.cancelScheduledPaymentsBatch.cancelScheduledPaymentsBatchError',
            { count: scheduledPaymentsInBatchCount },
          ),
        }),
      );

      throw error;
    }

    dispatch(
      refreshOneScheduledPaymentsBatch(scheduledPaymentsBatchId, {
        status: [ScheduledPaymentsBatchStatus.Pending],
      }),
    );

    dispatch(fetchExpenseClaimsCounts());

    dispatch(expenseClaimsActions.cancelScheduledPaymentsBatchSuccess());
  };

export const downloadCsvBatchDetails =
  (batchId: string) =>
  async (
    dispatch: ThunkDispatch<AppState, null, ExpenseClaimsActions>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getCompanyId(state);
    let data;

    dispatch(expenseClaimsActions.downloadCsvBatchDetailsRequest());

    try {
      const res = await companyAPI.get<Blob>(
        `/scheduled_payments_batches/${batchId}/download-csv-details`,
        {
          responseType: 'blob',
          companyId,
        },
      );

      data = res.data;
    } catch (error) {
      dispatch(expenseClaimsActions.downloadCsvBatchDetailsFailure());

      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t(
            'expenseClaims.history.downloadCsvBatchDetails.downloadCsvBatchDetailsFailure',
          ),
        }),
      );

      throw error;
    }

    downloadFromBlob(data, 'batch-details.csv');

    dispatch(expenseClaimsActions.downloadCsvBatchDetailsSuccess());
  };
