import { type PropsWithChildren } from 'react';

import { BetaFeatureNotice } from 'src/core/common/components/BetaFeatureNotice';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useUser } from 'src/core/modules/app/hooks/useUser';

export const MultiEntityHubOverviewPageHeader = ({
  children,
}: PropsWithChildren) => {
  const user = useUser();
  const { t } = useTranslation('global');

  return (
    <div className="flex flex-col content-stretch gap-s">
      <div className="flex items-center gap-m">
        <h1 className="leading-tight title-xxl">
          {t('organisation.reporting.page.greeting', {
            firstName: user.first_name,
          })}
        </h1>
        {/* TODO Remove this block when BETA is over */}
        <div className="ml-auto">
          <BetaFeatureNotice
            featureName={FEATURES.ORGANISATION_REPORTING_PAGE}
            feedbackTags={['organisation-reporting-page']}
            showOnlyFeedbackModal
            tag={<></>}
          />
        </div>
      </div>

      <div className="flex items-center justify-between gap-s">
        <div className="text-neutral-dark body-xl">
          <p>{t('organisation.reporting.page.subgreeting')}</p>
        </div>
        {children}
      </div>
    </div>
  );
};
