import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { GraphQLProvider } from 'modules/bookkeep';
import { AccountingBankAccounts } from 'modules/onboarding/setup-hub/pages/Accounting/AccountingBankAccounts';

import { AccountingEmployeeAccounts } from './Accounting/AccountingEmployeeAccounts';
import { AccountingExpenseAccounts } from './Accounting/AccountingExpenseAccounts';
import { AccountingExpenseAccountsList } from './Accounting/AccountingExpenseAccountsList';
import { AccountingSoftware } from './Accounting/AccountingSoftware';
import { AccountingSupplierAccounts } from './Accounting/AccountingSupplierAccounts';
import { AccountingSupplierAccountsDefault } from './Accounting/AccountingSupplierAccountsDefault';
import { AccountingSupplierAccountsList } from './Accounting/AccountingSupplierAccountsList';
import { ControlSettingsControlRules } from './ControlSettings/ControlSettingsControlRules';
import { ControlSettingsCustomFieldList } from './ControlSettings/ControlSettingsCustomFieldList';
import { ControlSettingsCustomFieldName } from './ControlSettings/ControlSettingsCustomFieldName';
import { ControlSettingsCustomFieldValues } from './ControlSettings/ControlSettingsCustomFieldValues';
import { ControlSettingsExpenseCategoryList } from './ControlSettings/ControlSettingsExpenseCategoryList';
import { ControlSettingsNewExpenseCategory } from './ControlSettings/ControlSettingsNewExpenseCategory';
import { SetupHubPage } from './SetupHubPage';
import { routes } from './routes';

export const SetupHubRoutes = () => {
  return (
    <GraphQLProvider>
      <Switch>
        <Route
          exact
          path={[routes.SETUP_HUB_TASK_LIST.path]}
          component={SetupHubPage}
        />
        <Route
          exact
          path={routes.CONTROL_SETTINGS_NEW_EXPENSE_CATEGORY.path}
          component={ControlSettingsNewExpenseCategory}
        />
        <Route
          exact
          path={routes.CONTROL_SETTINGS_EXPENSE_CATEGORY_LIST.path}
          component={ControlSettingsExpenseCategoryList}
        />
        <Route
          exact
          path={routes.CONTROL_SETTINGS_CUSTOM_FIELD_NAME.path}
          component={ControlSettingsCustomFieldName}
        />
        <Route
          exact
          path={routes.CONTROL_SETTINGS_CUSTOM_FIELD_VALUES.path}
          component={ControlSettingsCustomFieldValues}
        />
        <Route
          exact
          path={routes.CONTROL_SETTINGS_CUSTOM_FIELD_LIST.path}
          component={ControlSettingsCustomFieldList}
        />
        <Route
          exact
          path={routes.CONTROL_SETTINGS_CONTROL_RULES.path}
          component={ControlSettingsControlRules}
        />
        <Route
          exact
          path={routes.ACCOUNTING_SOFTWARE.path}
          component={AccountingSoftware}
        />
        <Route
          exact
          path={routes.ACCOUNTING_BANK_ACCOUNT.path}
          component={AccountingBankAccounts}
        />
        <Route
          exact
          path={routes.ACCOUNTING_EXPENSE_ACCOUNTS.path}
          component={AccountingExpenseAccounts}
        />
        <Route
          exact
          path={routes.ACCOUNTING_EXPENSE_ACCOUNTS_LIST.path}
          component={AccountingExpenseAccountsList}
        />
        <Route
          exact
          path={routes.ACCOUNTING_EMPLOYEE_ACCOUNTS.path}
          component={AccountingEmployeeAccounts}
        />
        <Route
          exact
          path={routes.ACCOUNTING_SUPPLIER_ACCOUNTS.path}
          component={AccountingSupplierAccounts}
        />
        <Route
          exact
          path={routes.ACCOUNTING_SUPPLIER_ACCOUNTS_LIST.path}
          component={AccountingSupplierAccountsList}
        />
        <Route
          exact
          path={routes.ACCOUNTING_SUPPLIER_ACCOUNTS_DEFAULT.path}
          component={AccountingSupplierAccountsDefault}
        />

        <Redirect to={routes.SETUP_HUB_TASK_LIST.path} />
      </Switch>
    </GraphQLProvider>
  );
};
