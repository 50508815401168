import { Button, Callout, FormField, TextInput } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useHasAccountingIntegrationCapability } from 'modules/accounting-integration/apis';
import { isSpanishDPRPayable } from 'modules/bookkeep/payables/models/payable';
import { type Payable } from 'modules/bookkeep/prepare-payables/models';
import { type DocumentaryEvidenceNumberAutomation } from 'modules/payable';
import { shouldDisplayInvoiceNumber } from 'modules/payable/components/PayableInvoiceNumberField/should-display-invoice-number';
import { AutomationIcon } from 'src/core/common/components/AutomationIcon';
import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';
import { LocalStorageKey } from 'src/core/constants/storage';
import { getLocalItem, setLocalItem } from 'src/core/utils/storage';

export type Props = {
  payable: Pick<Payable, 'id' | 'type' | 'documentaryEvidence' | 'automation'>;
  value: string | undefined;
  onChange: (value: string) => void;
  error: string | undefined;
  className?: string;
};

export const PayableInvoiceNumberFormField = ({
  payable,
  value,
  onChange,
  error,
  className,
}: Props) => {
  const { t } = useTranslation();

  const integrationHasInvoiceNumberCapability =
    useHasAccountingIntegrationCapability('invoiceNumber');

  const [hiddenCalloutCounter, setHiddenCalloutCounter] = useState(
    hiddenCalloutCache.size,
  );

  const setHiddenCallout = (payableId: string) => {
    hiddenCalloutCache.add(payableId);

    setHiddenCalloutCounter(hiddenCalloutCounter + 1);

    if (hiddenCalloutCache.size > 200) {
      hiddenCalloutCache = new Set(Array.from(hiddenCalloutCache).slice(-200));
    }

    setLocalItem(
      LocalStorageKey.PayablesInvoiceNumberHiddenCallout,
      Array.from(hiddenCalloutCache),
    );
  };

  const hasSpanishDPR = isSpanishDPRPayable(payable);

  const automation = payable.automation?.documentaryEvidenceNumber;

  const mustDisplayInvoiceNumber = shouldDisplayInvoiceNumber(
    {
      integrationHasInvoiceNumberCapability,
      hasSpanishDPR,
    },
    payable,
  );

  const invoiceNumberLabel = (
    <div className="flex flex-row items-center gap-xs">
      {computeInvoiceNumberLabel(payable, t as TGlobalFunctionTyped)}
    </div>
  );

  if (!mustDisplayInvoiceNumber) {
    return <></>;
  }

  const isFieldRequired =
    integrationHasInvoiceNumberCapability || hasSpanishDPR;

  const isAutomatedValue = checkIsAutomatedValue(automation, value);

  const hasRecommendation = checkHasRecommendation(automation, value);

  return (
    <>
      <FormField
        infoTipContent={
          hasSpanishDPR
            ? t('expenseInbox.expenseEditor.receiptNumberSpanishDPRTooltip')
            : undefined
        }
        className={className}
        label={invoiceNumberLabel as unknown as string}
        hint={!isFieldRequired ? t('misc.optional') : undefined}
        alertMessage={typeof error === 'string' ? t(error) : undefined}
      >
        <TextInput
          value={value}
          onChange={(event) => onChange(event.target.value)}
          name="invoice-number"
          variant={isAutomatedValue ? 'magicGradient' : 'default'}
          maxLength={255}
          leftAddon={
            isAutomatedValue ? (
              <AutomationIcon
                message={t(
                  'payables.panel.documentaryEvidenceNumberPrefilledByRecommendation',
                )}
                className="ml-xs"
              />
            ) : null
          }
        />

        {!hiddenCalloutCache.has(payable.id) &&
          hasRecommendation &&
          automation?.documentaryEvidenceNumber && (
            <Callout
              className="mt-xs"
              variant="warning"
              iconName="robot"
              title={t('expenseInbox.expenseEditor.considerUpdatingValue')}
            >
              {t(
                'expenseInbox.expenseEditor.documentaryEvidenceNumberUpdateValue',
                {
                  number: automation?.documentaryEvidenceNumber,
                },
              )}
              <div className="mt-s flex flex-row gap-xs">
                <Button
                  text={t('expenseInbox.expenseEditor.updateValue')}
                  onClick={() => {
                    onChange(automation?.documentaryEvidenceNumber ?? '');
                  }}
                  variant="warning"
                />
                <Button
                  text={t('expenseInbox.expenseEditor.ignore')}
                  onClick={() => {
                    setHiddenCallout(payable.id);
                  }}
                  variant="contrasted"
                />
              </div>
            </Callout>
          )}
      </FormField>
    </>
  );
};

/**
 * Helpers
 */

/**
 * we intend to display a label for the field based on the payable
 * type and the type of the documentary evidence, as follows:
 *
 * For invoices -> Invoice number
 * For credit notes -> Credit note number
 * For expense claims, cards (all types), card refunds -> Receipt number
 *
 * Since we always need to display something for the field, we default to
 * 'Invoice number' for any other case.
 */
export function computeInvoiceNumberLabel(
  payable: Pick<Payable, 'type' | 'documentaryEvidence'>,
  t: TGlobalFunctionTyped,
) {
  if (payable.documentaryEvidence?.type === 'creditNote') {
    return t('payables.panel.creditNoteNumber');
  }

  switch (payable.type) {
    case 'expense_claim':
    case 'card_purchase':
    case 'reversal':
      return t('payables.panel.receiptNumberLabel');
    default:
      return t('payables.panel.invoiceNumber');
  }
}

export const checkIsAutomatedValue = (
  automation: DocumentaryEvidenceNumberAutomation | undefined,
  value?: string | null,
): automation is DocumentaryEvidenceNumberAutomation => {
  return Boolean(
    value &&
    automation?.isAppliedOnPayable &&
    automation?.kind === 'recommendation' &&
    automation?.documentaryEvidenceNumber === value,
  );
};

const checkHasRecommendation = (
  automation: DocumentaryEvidenceNumberAutomation | undefined,
  value?: string | null,
): automation is DocumentaryEvidenceNumberAutomation => {
  return Boolean(
    value &&
    automation?.isAppliedOnPayable === false &&
    automation?.kind === 'recommendation' &&
    automation?.documentaryEvidenceNumber !== value,
  );
};

let hiddenCalloutCache = new Set<string>(
  getLocalItem(LocalStorageKey.PayablesInvoiceNumberHiddenCallout),
);
