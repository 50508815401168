import { useState } from 'react';

// TODO Should be moved to common components folder
export const FilteredListBySearchWrapper = <T extends object>({
  items,
  children,
  searchableFields,
}: {
  items: T[];
  searchableFields: (keyof T)[];
  children: ({
    search,
    setSearch,
    filteredItems,
  }: {
    search: string;
    setSearch: (search: string) => void;
    filteredItems: T[];
  }) => React.ReactNode;
}) => {
  const [search, setSearch] = useState<string>('');
  const filterBySearchTerm = (item: T) => {
    const sanitizedTerm = search.trim().toLowerCase();

    return searchableFields
      .map((field) => String(item[field]).toLowerCase())
      .some((itemField) => itemField.includes(sanitizedTerm));
  };

  return children({
    search,
    setSearch,
    filteredItems: items.filter((entity) => filterBySearchTerm(entity)),
  });
};
