import { DimensionApprovalFlowPanelContent } from './DimensionApprovalFlowPanelContent';
import { StandardApprovalFlowPanelContent } from './StandardApprovalFlowPanelContent';
import { useWorkflowContext } from '../../WorkflowContext';
import { type CustomNode } from '../../node';
import { FloatingPanel } from '../FloatingPanel/FloatingPanel';

export type NodeSettingsFormProps = {
  condition: string;
  conditionValue: string;
};

export const NodeSettingsPanel = () => {
  const { selectedNodeId, nodes, setSelectedNodeId } = useWorkflowContext();
  const currentNode = nodes.find((node) => node.id === selectedNodeId);
  const isOpen = Boolean(selectedNodeId && currentNode);
  const schemeId = currentNode ? getSchemeId(currentNode) : undefined;
  const panelType = getPanelType(currentNode?.type);
  const handleClosePanel = () => {
    setSelectedNodeId(null);
  }

  return (
    <FloatingPanel isOpen={isOpen} onClose={handleClosePanel}>
      {panelType === 'standardApprovalFlow' && schemeId && (
        <StandardApprovalFlowPanelContent
          approvalSchemeId={schemeId}
          onClose={handleClosePanel}
        />
      )}
      {panelType === 'dimensionApprovalFlow' && (
        <DimensionApprovalFlowPanelContent onCancel={handleClosePanel} />
      )}
    </FloatingPanel>
  );
};

const getSchemeId = (node: CustomNode) => {
  if (node.type === 'standardApprovalFlowNode' || node.type === 'baseNode') {
    return node.data.schemeId;
  }
  return undefined;
}

const getPanelType = (
  nodeType: 'labelNode' | 'standardApprovalFlowNode' | 'baseNode' | undefined,
) => {
  if (nodeType === 'standardApprovalFlowNode') {
    return 'standardApprovalFlow';
  }
  return 'dimensionApprovalFlow';
};
