import {
  Callout,
  type CalloutVariant,
  type IconName,
} from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { type useOrganisationEntityWalletSummaryQuery } from 'src/core/modules/organisation-reporting/hooks/data/useOrganisationEntityWalletSummaryQuery';
import { type OrganisationReportingEntity } from 'src/core/modules/organisation-reporting/types';

import styles from './OrganisationReportingEntityStatisticsCallout.module.css';

export type OrganisationReportingEntityStatusForCallout =
  | 'awaitingKycApproval'
  | 'kycInProgress'
  | 'churning'
  | 'churningWithRemainingFunds'
  | 'churned'
  | 'churnedWithRemainingFunds'
  | null; // null is used to indicate that the entity is active

type Props = {
  state: OrganisationReportingEntityStatusForCallout;
  entityId: string;
};

export const OrganisationReportingEntityStatisticsCallout = ({
  state,
  entityId,
}: Props) => {
  const { t } = useTranslation('global');

  const calloutAttributes = getCalloutAttributes(state, entityId, t);

  return (
    calloutAttributes && (
      <Callout
        title={calloutAttributes.title}
        iconName={calloutAttributes.iconName}
        variant={calloutAttributes.variant}
        className={styles.callout}
      />
    )
  );
};

const getCalloutAttributes = (
  state: Props['state'],
  entityId: Props['entityId'],
  t: TGlobalFunctionTyped,
):
  | {
      iconName: IconName;
      variant: CalloutVariant;
      title: string | ReactNode;
    }
  | undefined => {
  if (state === 'awaitingKycApproval') {
    return {
      iconName: 'clock-full',
      variant: 'info',
      title: t(
        'organisation.reporting.page.entities.entity.callout.awaitingKycApproval',
      ),
    };
  }
  if (state === 'kycInProgress') {
    return {
      iconName: 'warning',
      variant: 'warning',
      title: (
        <Trans i18nKey="organisation.reporting.page.entities.entity.callout.kycInProgress">
          <Link
            to={routeFor(routes.ONBOARDING_HUB.path, {
              company: entityId,
            })}
            target="_blank"
            className="leading-[20px] text-warning underline title-m"
          >
            -
          </Link>
        </Trans>
      ),
    };
  }
  if (state === 'churning') {
    return {
      iconName: 'info',
      variant: 'neutral',
      title: (
        <Trans i18nKey="organisation.reporting.page.entities.entity.callout.churning">
          <Link
            to={routeFor(routes.CHURN.path, {
              company: entityId,
            })}
            target="_blank"
            className="leading-[20px] text-neutral-darker underline title-m"
          >
            -
          </Link>
        </Trans>
      ),
    };
  }
  if (state === 'churningWithRemainingFunds') {
    return {
      iconName: 'info',
      variant: 'neutral',
      title: t(
        'organisation.reporting.page.entities.entity.callout.churningWithRemainingFunds',
      ),
    };
  }
  if (state === 'churned') {
    return {
      iconName: 'info',
      variant: 'neutral',
      title: t('organisation.reporting.page.entities.entity.callout.churned'),
    };
  }
  if (state === 'churnedWithRemainingFunds') {
    return {
      iconName: 'info',
      variant: 'neutral',
      title: t(
        'organisation.reporting.page.entities.entity.callout.churnedWithRemainingFunds',
      ),
    };
  }

  return undefined;
};

/**
 * Get the status of the entity to display in the callout
 * `null` means the entity is active,
 * `undefined` indicates that the status is not yet available (query is still loading)
 */
export const getEntityStatus = (
  entity: OrganisationReportingEntity,
  entityWalletSummaryQueryState: ReturnType<
    typeof useOrganisationEntityWalletSummaryQuery
  >,
): OrganisationReportingEntityStatusForCallout | undefined => {
  if (entityWalletSummaryQueryState.status !== 'success') {
    return undefined;
  }

  const { data } = entityWalletSummaryQueryState;

  if (data.isKycInProgress) {
    return 'kycInProgress';
  }
  if (data.isKycAwaitingApproval) {
    return 'awaitingKycApproval';
  }
  if (entity.isChurning) {
    return data.walletBalance > 0 ? 'churningWithRemainingFunds' : 'churning';
  }
  if (entity.hasChurned) {
    return data.walletBalance > 0 ? 'churnedWithRemainingFunds' : 'churned';
  }

  return null;
};
