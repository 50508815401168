import { Callout, FormField, SkeletonText } from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { companyAPI } from 'src/core/api/axios';

import { useMembersQuery } from '../../hooks';
import { getMemberName, type Member } from '../../models/member';

type Props = {
  toDeleteMember: Member;
  error: string | undefined;
  onSelectMemberId: (id: string) => void;
  setIsScreeningLoading: (isLoading: boolean) => void;
  screeningResultUsername: string;
  setScreeningResultUsername: (screeningResultUsername: string) => void;
};

export const TransferToMemberAutocompleteContainer = ({
  toDeleteMember,
  error,
  onSelectMemberId,
  setIsScreeningLoading,
  screeningResultUsername,
  setScreeningResultUsername,
}: Props) => {
  const { t } = useTranslation('global');
  const [selectedMemberOption, setSelectedMemberOption] = useState<{
    key: string;
    label: string;
  }>();

  const membersQueryState = useMembersQuery();
  const company = useCompany();

  return (
    <QuerySuspense
      loading={<SkeletonText size="xxl" />}
      fallback={() => (
        <Callout
          variant="warning"
          title={t('members.deleteModal.loadMembersError')}
        />
      )}
      queryState={membersQueryState}
    >
      {(members) => (
        <FormField
          label={t('members.subscriptionsWillBeTransferedTo', {
            userName: getMemberName(toDeleteMember),
          })}
          alertMessage={error}
        >
          <AutocompleteSearch
            fit="parent"
            name="transferringMember"
            placeholder={t('members.deleteModal.transferringMember')}
            value={selectedMemberOption}
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions value={rawValue} />
            )}
            options={getMembersToTransfer(members, toDeleteMember.id).map(
              (member) => ({
                key: member.id,
                label: member.displayName,
              }),
            )}
            onSelect={async (member) => {
              if (member) {
                setIsScreeningLoading(true);
                try {
                  const response = await companyAPI.get(
                    `/users/${member.key}/screening-information-status`,
                    {
                      companyId: company.id,
                    },
                  );
                  if (
                    response?.data?.status === 'pending' &&
                    company.banking_provider === 'sfs'
                  ) {
                    setScreeningResultUsername(member.label);
                    return;
                  }
                  setScreeningResultUsername('');

                  setSelectedMemberOption(member);
                  onSelectMemberId(member.key);
                } catch {
                  setScreeningResultUsername('');
                } finally {
                  setIsScreeningLoading(false);
                }
              }
            }}
          />
          {screeningResultUsername && (
            <Callout
              variant="alert"
              className="mt-xs"
              title={t('subscriptions.panelDetails.ownerNeedsScreeningError', {
                userName: screeningResultUsername,
              })}
            />
          )}
        </FormField>
      )}
    </QuerySuspense>
  );
};

const getMembersToTransfer = (
  members: Member[],
  toDeleteMemberId: string,
): Member[] => {
  return members.filter(
    (member) => !member.isPending && member.id !== toDeleteMemberId,
  );
};
