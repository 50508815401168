import { Skeleton } from '@dev-spendesk/grapes';
import React from 'react';

import { TextFitter } from '../../../TextFitter';

export const EntityStatisticBlock = ({
  title,
  contents,
}: {
  title: string;
  contents: React.ReactNode[];
}) => (
  <div className="flex h-full w-[144px] flex-col gap-xs rounded-[8px] bg-page-background px-xs py-s">
    <h3 className="text-complementary title-m">
      <TextFitter maxLines={2}>{title}</TextFitter>
    </h3>
    <div className="flex flex-col gap-xxs">
      {React.Children.map(contents, (content) => (
        <div key={`statistic-block-${content?.toString()}`}>{content}</div>
      ))}
    </div>
  </div>
);

export const EntityStatisticBlockSkeleton = () => (
  <div className="flex h-full w-[144px] rounded-[8px]">
    <Skeleton width="100%" height="100%" />
  </div>
);
