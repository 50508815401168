import type { I18nKey } from 'common/hooks/useTranslation';
import { routes as setupHubRoutes } from 'modules/onboarding/setup-hub/pages/routes';
import type { HubTask, HubTaskType } from 'modules/onboarding/types/hubTask';
import { routeFor, routes } from 'src/core/constants/routes';

export const getContentByTaskType = (
  type: HubTaskType,
): { title: I18nKey; description: I18nKey } | null => {
  switch (type) {
    case 'physical_cards':
      return {
        title: 'setupHub.sections.controlSettings.physicalCards.title',
        description:
          'setupHub.sections.controlSettings.physicalCards.description',
      };
    case 'expense_categories':
      return {
        title: 'setupHub.sections.controlSettings.expenseCategories.title',
        description:
          'setupHub.sections.controlSettings.expenseCategories.description',
      };
    case 'analytical_fields':
      return {
        title: 'setupHub.sections.controlSettings.analyticalFields.title',
        description:
          'setupHub.sections.controlSettings.analyticalFields.description',
      };
    case 'control_rules':
      return {
        title: 'setupHub.sections.controlSettings.controlRules.title',
        description:
          'setupHub.sections.controlSettings.controlRules.description',
      };
    case 'accounting_software':
      return {
        title:
          'setupHub.sections.accounting.initAccounting.accountingSoftware.title',
        description:
          'setupHub.sections.accounting.initAccounting.accountingSoftware.description',
      };
    case 'bank_account_codes':
      return {
        title:
          'setupHub.sections.accounting.accountsChart.bankAccountCodes.title',
        description:
          'setupHub.sections.accounting.accountsChart.bankAccountCodes.description',
      };
    case 'expense_accounts_codes':
      return {
        title:
          'setupHub.sections.accounting.accountsChart.expenseAccountsCodes.title',
        description:
          'setupHub.sections.accounting.accountsChart.expenseAccountsCodes.description',
      };
    case 'employee_account_codes':
      return {
        title:
          'setupHub.sections.accounting.accountsChart.employeeAccountCodes.title',
        description:
          'setupHub.sections.accounting.accountsChart.employeeAccountCodes.description',
      };
    case 'account_payable_codes':
      return {
        title:
          'setupHub.sections.accounting.accountsChart.accountPayableCodes.title',
        description:
          'setupHub.sections.accounting.accountsChart.accountPayableCodes.description',
      };
    case 'vat_codes':
      return {
        title: 'setupHub.sections.accounting.accountsChart.vatCodes.title',
        description:
          'setupHub.sections.accounting.accountsChart.vatCodes.description',
      };
    case 'journal_export':
      return {
        title:
          'setupHub.sections.accounting.exportsSettings.journalExport.title',
        description:
          'setupHub.sections.accounting.exportsSettings.journalExport.description',
      };
    case 'receipt_file_name':
      return {
        title:
          'setupHub.sections.accounting.exportsSettings.receiptFileName.title',
        description:
          'setupHub.sections.accounting.exportsSettings.receiptFileName.description',
      };
    default:
      return null;
  }
};

type TaskUrl = {
  ongoingUrl: string;
  completedUrl: string;
};

export const getTaskUrl = (task: HubTask, companyId: string): TaskUrl => {
  switch (task.type) {
    case 'physical_cards':
      return {
        completedUrl: routeFor(routes.CARDS.path, { company: companyId }),
        ongoingUrl: routeFor(routes.CARDS_ORDER.path, { company: companyId }),
      };
    case 'expense_categories':
      return {
        completedUrl: routeFor(routes.COMPANY_CATEGORIES.path, {
          company: companyId,
        }),
        ongoingUrl: routeFor(
          setupHubRoutes.CONTROL_SETTINGS_NEW_EXPENSE_CATEGORY.path,
          { company: companyId },
        ),
      };
    case 'analytical_fields':
      return {
        completedUrl: routeFor(routes.COMPANY_CATEGORIES.path, {
          company: companyId,
        }),
        ongoingUrl: routeFor(
          setupHubRoutes.CONTROL_SETTINGS_CUSTOM_FIELD_NAME.path,
          { company: companyId },
        ),
      };
    case 'control_rules':
      return {
        completedUrl: routeFor(routes.COMPANY_CONTROL_RULES.path, {
          company: companyId,
        }),
        ongoingUrl: routeFor(
          setupHubRoutes.CONTROL_SETTINGS_CONTROL_RULES.path,
          { company: companyId },
        ),
      };
    case 'accounting_software': {
      return {
        completedUrl: routeFor(routes.COMPANY_ACCOUNTING_INTEGRATION.path, {
          company: companyId,
        }),
        ongoingUrl: routeFor(setupHubRoutes.ACCOUNTING_SOFTWARE.path, {
          company: companyId,
        }),
      };
    }
    case 'bank_account_codes':
      return {
        completedUrl: routeFor(routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path, {
          company: companyId,
        }),
        ongoingUrl: routeFor(setupHubRoutes.ACCOUNTING_BANK_ACCOUNT.path, {
          company: companyId,
        }),
      };
    case 'expense_accounts_codes': {
      return {
        completedUrl: routeFor(
          routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path,
          {
            company: companyId,
          },
        ),
        ongoingUrl: routeFor(setupHubRoutes.ACCOUNTING_EXPENSE_ACCOUNTS.path, {
          company: companyId,
        }),
      };
    }
    case 'employee_account_codes': {
      return {
        completedUrl: routeFor(
          routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path,
          {
            company: companyId,
          },
        ),
        ongoingUrl: routeFor(setupHubRoutes.ACCOUNTING_EMPLOYEE_ACCOUNTS.path, {
          company: companyId,
        }),
      };
    }
    case 'account_payable_codes': {
      return {
        completedUrl: routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
          company: companyId,
        }),
        ongoingUrl: routeFor(setupHubRoutes.ACCOUNTING_SUPPLIER_ACCOUNTS.path, {
          company: companyId,
        }),
      };
    }
    default:
      throw new Error(`Unexpected task type ${task.type} in Setup Hub`);
  }
};
