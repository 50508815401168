import { IconButton, Tag } from '@dev-spendesk/grapes';
import { Handle, Position, type NodeProps } from '@xyflow/react';
import classNames from 'classnames';

import { useWorkflowContext } from '../../WorkflowContext';
import { ApprovalSchemeNodePreview } from '../ApprovalSchemeNodePreview';

export type StandardApprovalFlowNodeData = {
  schemeId?: string;
};

type Props = NodeProps & {
  data: StandardApprovalFlowNodeData;
};

const handleStyle = {
  background: 'transparent',
  border: 'none',
  width: 0,
  height: 0,
};

export const StandardApprovalFlowNode = ({ data, id }: Props) => {
  const { setSelectedNodeId, selectedNodeId } = useWorkflowContext();
  const isSelected = selectedNodeId === id;

  return (
    <div className="flex w-[340px] items-center">
      <div
        className={classNames(
          'rounded p-s',
          isSelected
            ? 'border border-solid border-primary bg-primary-lightest'
            : 'bg-white',
        )}
      >
        <div className="flex items-center justify-between">
          <Tag variant="primary">Standard approval workflow</Tag>
          <div className="flex items-center">
            <IconButton
              aria-label="edit"
              onClick={() => {
                setSelectedNodeId(id);
              }}
              iconName="pen"
            />
          </div>
        </div>
        <div className="mt-xs text-neutral-darker body-s">
          This workflow will be executed if no other workflow applies. This
          ensures that any request has a reviewer.
        </div>
        {data.schemeId && (
          <ApprovalSchemeNodePreview approvalSchemeId={data.schemeId} />
        )}
      </div>
      <Handle
        style={handleStyle}
        type="target"
        id="topHandle"
        position={Position.Top}
      />
    </div>
  );
};
