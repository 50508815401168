import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type OrganisationReportingEntityBreakdown } from '../../../types';
import {
  BreakdownGauge,
  type BreakdownGaugeBar,
  BreakdownGaugeLegend,
} from '../BreakdownGauge';

type EntityBreakdownBlockProps = {
  currency: string;
  breakdown: OrganisationReportingEntityBreakdown;
  isInactive?: boolean;
};

export const EntityBreakdownBlock = ({
  currency,
  breakdown,
  isInactive = false,
}: EntityBreakdownBlockProps) => {
  const { t } = useTranslation('global');

  const formatAsMonetaryValue = (amount: number): string =>
    formatMonetaryValue({
      amount,
      currency,
      precision: 2,
    });

  const breakdownBars: BreakdownGaugeBar[] = [
    {
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.scheduledTransfers.label',
      ),
      value: breakdown.scheduledTransfers,
      valueLabel: formatAsMonetaryValue(breakdown.scheduledTransfers),
      valueLabelColor: breakdown.scheduledTransfers > 0 ? undefined : 'neutral',
      valueLabelTooltip: t(
        'organisation.reporting.page.entities.entity.breakdown.scheduledTransfers.tooltip',
      ),
      type: 'full',
    },
    {
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.cardsLimits.label',
      ),
      value: breakdown.cardsLimits,
      valueLabel: formatAsMonetaryValue(breakdown.cardsLimits),
      valueLabelColor: breakdown.cardsLimits > 0 ? undefined : 'neutral',
      valueLabelTooltip: t(
        'organisation.reporting.page.entities.entity.breakdown.cardsLimits.tooltip',
      ),
      type: 'striped',
    },
  ];

  if (breakdown.trend === 'warning') {
    breakdownBars.push({
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.shortfall',
      ),
      value: breakdown.shortfall,
      valueLabel: formatAsMonetaryValue(breakdown.shortfall),
      valueLabelColor: 'warning',
      type: 'empty',
      hideBar: true,
    });
  } else {
    breakdownBars.push({
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.available',
      ),
      value: breakdown.available,
      valueLabel: formatAsMonetaryValue(breakdown.available),
      valueLabelColor: breakdown.available > 0 ? 'positive' : 'neutral',
      type: 'empty',
    });
  }

  return (
    <div>
      <BreakdownGauge
        bars={breakdownBars}
        trend={breakdown.trend}
        isInactive={isInactive}
      >
        {(preparedBars) => (
          <BreakdownGaugeLegend preparedBars={preparedBars} isCondensed />
        )}
      </BreakdownGauge>
    </div>
  );
};
