import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
  type NativeAccountingIntegration,
} from 'modules/bookkeep/integration/status';
import type { I18nKey } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import acdLogo from './assets/acd.svg';
import cegidLogo from './assets/cegid.svg';
import datevLogo from './assets/datev.svg';
import netsuiteLogo from './assets/netsuite.svg';
import quickbooksLogo from './assets/quickbooks.svg';
import sageLogo from './assets/sage.svg';
import sage100Logo from './assets/sage100.svg';
import doubleEntryLogo from './assets/spendesk-double-entry.svg';
import singleEntryLogo from './assets/spendesk-single-entry.svg';
import xeroLogo from './assets/xero.svg';

type CommonAccountingIntegrationDetails = {
  isBeta: false;
  logoPath: string;
  i18nSelectTitle: I18nKey;
  i18nSelectDescription: I18nKey;
  i18nSelectType: I18nKey;
  i18nSwitchTitle: I18nKey;
  i18nSwitchWarningTitle: I18nKey;
};

type BetaAccountingIntegrationDetails = Omit<
  CommonAccountingIntegrationDetails,
  'isBeta'
> & {
  isBeta: true;
  betaFeature: FEATURES;
  betaTag: string;
};

type AccountingIntegrationDetails =
  | CommonAccountingIntegrationDetails
  | BetaAccountingIntegrationDetails;

export function getAccountingIntegrationDetails(
  accountingIntegration: AccountingSoftware,
): AccountingIntegrationDetails {
  const i18nSelectType = hasIntegrationFileBasedExport(accountingIntegration)
    ? 'bookkeep.integrations.settings.fileBasedIntegration'
    : 'bookkeep.integrations.settings.nativeIntegration';

  const isBeta = false;

  switch (accountingIntegration) {
    case 'SpendeskAccounting':
      return {
        isBeta,
        logoPath: doubleEntryLogo,
        i18nSelectTitle:
          'bookkeep.integrations.settings.spendeskDoubleEntryTitle',
        i18nSelectDescription:
          'bookkeep.integrations.settings.spendeskDoubleEntryContent',
        i18nSelectType,
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleDoubleEntry',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.doubleEntry',
      };
    case 'SpendeskAccountingSingleEntry':
      return {
        isBeta,
        logoPath: singleEntryLogo,
        i18nSelectTitle:
          'bookkeep.integrations.settings.spendeskSingleEntryTitle',
        i18nSelectDescription:
          'bookkeep.integrations.settings.spendeskSingleEntryContent',
        i18nSelectType,
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleSingleEntry',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.singleEntry',
      };
    case 'Sage':
      return {
        isBeta,
        logoPath: sageLogo,
        i18nSelectTitle: 'bookkeep.integrations.settings.sageTitle',
        i18nSelectDescription: 'bookkeep.integrations.settings.sageContent',
        i18nSelectType,
        i18nSwitchTitle: 'bookkeep.integrations.settings.baseSwitch.sage',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.sage',
      };
    case 'Cegid':
      return {
        isBeta,
        logoPath: cegidLogo,
        i18nSelectTitle: 'bookkeep.integrations.settings.cegidTitle',
        i18nSelectDescription: 'bookkeep.integrations.settings.cegidContent',
        i18nSelectType,
        i18nSwitchTitle: 'bookkeep.integrations.settings.baseSwitch.cegid',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.cegid',
      };
    case 'Xero':
      return {
        isBeta,
        logoPath: xeroLogo,
        i18nSelectTitle:
          'bookkeep.integrations.settings.integrationTitles.xero',
        i18nSelectDescription: 'bookkeep.integrations.xero.selectorDescription',
        i18nSelectType,
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleXero',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.xero',
      };
    case 'Datev':
      return {
        isBeta,
        logoPath: datevLogo,
        i18nSelectTitle:
          'bookkeep.integrations.settings.integrationTitles.datev',
        i18nSelectDescription: 'bookkeep.integrations.settings.datevContent',
        i18nSelectType,
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleDatev',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.datev',
      };
    case 'Quickbooks':
      return {
        isBeta,
        logoPath: quickbooksLogo,
        i18nSelectType,
        i18nSelectTitle:
          'bookkeep.integrations.settings.integrationTitles.quickBooks',
        i18nSelectDescription:
          'bookkeep.integrations.settings.quickbooksContent',
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleQuickbooks',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.quickbooks',
      };
    case 'Netsuite':
      return {
        isBeta,
        logoPath: netsuiteLogo,
        i18nSelectTitle:
          'bookkeep.integrations.settings.integrationTitles.netsuite',
        i18nSelectDescription: 'bookkeep.integrations.settings.netsuiteContent',
        i18nSelectType,
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleNetsuite',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.netsuite',
      };
    case 'Sage100':
      return {
        isBeta: true,
        betaFeature: FEATURES.TMP_NATIVE_SAGE100_BETA,
        betaTag: 'Sage100',
        // TODO replace the svg with the proper logo
        // when we get it from the designer
        logoPath: sage100Logo,
        i18nSelectTitle:
          'bookkeep.integrations.settings.integrationTitles.sage100',
        i18nSelectDescription: 'bookkeep.integrations.settings.chiftContent',
        i18nSelectType,
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleSage100',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.sage100',
      };
    case 'ACD':
      return {
        isBeta: true,
        betaFeature: FEATURES.TMP_NATIVE_ACD_BETA,
        betaTag: 'ACD',
        // TODO replace the svg with the proper logo
        // when we get it from the designer
        logoPath: acdLogo,
        i18nSelectTitle: 'bookkeep.integrations.settings.integrationTitles.acd',
        i18nSelectDescription: 'bookkeep.integrations.settings.chiftContent',
        i18nSelectType,
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleACD',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.acd',
      };
    case 'Odoo':
      return {
        isBeta: true,
        betaFeature: FEATURES.TMP_NATIVE_ODOO_BETA,
        betaTag: 'ODOO',
        // TODO replace the svg with the proper logo
        // when we get it from the designer
        logoPath: acdLogo,
        i18nSelectTitle:
          'bookkeep.integrations.settings.integrationTitles.odoo',
        i18nSelectDescription: 'bookkeep.integrations.settings.chiftContent',
        i18nSelectType,
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleOdoo',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.odoo',
      };
    case 'ExactOnlineFr':
      return {
        isBeta: true,
        betaFeature: FEATURES.TMP_NATIVE_EXACT_ONLINE_FR_BETA,
        betaTag: 'ExactOnlineFr',
        // TODO replace the svg with the proper logo
        // when we get it from the designer
        logoPath: acdLogo,
        i18nSelectTitle:
          'bookkeep.integrations.settings.integrationTitles.exactOnlineFr',
        i18nSelectDescription: 'bookkeep.integrations.settings.chiftContent',
        i18nSelectType,
        i18nSwitchTitle:
          'bookkeep.integrations.settings.baseSwitch.summaryTitleExactOnlineFr',
        i18nSwitchWarningTitle:
          'bookkeep.integrations.settings.baseSwitch.warning.title.exactOnlineFr',
      };
    default: {
      rejectUnexpectedValue('integration', accountingIntegration);
    }
  }
}

export function getNativeAccountingIntegrationDetails(
  nativeIntegration: NativeAccountingIntegration,
): AccountingIntegrationDetails & {
  i18nPreloginTitle: string;
  i18nPreloginSubtitle: (isSwitching: boolean) => string;
  i18nPreloginConfirm: (isSwitching: boolean) => string;
} {
  switch (nativeIntegration) {
    case 'Xero':
      return getDetailsForXero(nativeIntegration);
    case 'Datev':
      return getDetailsForDatev(nativeIntegration);
    case 'Quickbooks':
      return getDetailsForQuickbooks(nativeIntegration);
    case 'Netsuite':
      return getDetailsForNetsuite(nativeIntegration);
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return getDetailsForChift(nativeIntegration);
    default: {
      rejectUnexpectedValue('native integration', nativeIntegration);
    }
  }
}

function getDetailsForXero(
  nativeIntegration: NativeAccountingIntegration,
): AccountingIntegrationDetails & {
  i18nPreloginTitle: string;
  i18nPreloginSubtitle: (isSwitching: boolean) => string;
  i18nPreloginConfirm: (isSwitching: boolean) => string;
} {
  return {
    ...getAccountingIntegrationDetails(nativeIntegration),
    i18nPreloginTitle: 'bookkeep.integrations.xero.preloginModal.title',
    i18nPreloginSubtitle: (isSwitching) =>
      isSwitching
        ? 'bookkeep.integrations.xero.preloginModal.subtitle.switching'
        : 'bookkeep.integrations.xero.preloginModal.subtitle.connecting',
    i18nPreloginConfirm: (isSwitching) =>
      isSwitching
        ? 'bookkeep.integrations.xero.preloginModal.confirmButton.switching'
        : 'bookkeep.integrations.xero.preloginModal.confirmButton.connecting',
  };
}

function getDetailsForDatev(
  nativeIntegration: NativeAccountingIntegration,
): AccountingIntegrationDetails & {
  i18nPreloginTitle: string;
  i18nPreloginSubtitle: (isSwitching: boolean) => string;
  i18nPreloginConfirm: (isSwitching: boolean) => string;
} {
  return {
    ...getAccountingIntegrationDetails(nativeIntegration),
    i18nPreloginTitle: 'bookkeep.integrations.datev.preloginModal.title',
    i18nPreloginSubtitle: (isSwitching) =>
      isSwitching
        ? 'bookkeep.integrations.datev.preloginModal.subtitle.switching'
        : 'bookkeep.integrations.datev.preloginModal.subtitle.connecting',
    i18nPreloginConfirm: (isSwitching) =>
      isSwitching
        ? 'bookkeep.integrations.datev.preloginModal.confirmButton.switching'
        : 'bookkeep.integrations.datev.preloginModal.confirmButton.connecting',
  };
}

function getDetailsForQuickbooks(
  nativeIntegration: NativeAccountingIntegration,
): AccountingIntegrationDetails & {
  i18nPreloginTitle: string;
  i18nPreloginSubtitle: (isSwitching: boolean) => string;
  i18nPreloginConfirm: (isSwitching: boolean) => string;
} {
  return {
    ...getAccountingIntegrationDetails(nativeIntegration),
    i18nPreloginTitle: 'bookkeep.integrations.quickbooks.preloginModal.title',
    i18nPreloginSubtitle: (isSwitching) =>
      isSwitching
        ? 'bookkeep.integrations.quickbooks.preloginModal.subtitle.switching'
        : 'bookkeep.integrations.quickbooks.preloginModal.subtitle.connecting',
    i18nPreloginConfirm: (isSwitching) =>
      isSwitching
        ? 'bookkeep.integrations.quickbooks.preloginModal.confirmButton.switching'
        : 'bookkeep.integrations.quickbooks.preloginModal.confirmButton.connecting',
  };
}

function getDetailsForNetsuite(
  nativeIntegration: NativeAccountingIntegration,
): AccountingIntegrationDetails & {
  i18nPreloginTitle: string;
  i18nPreloginSubtitle: (isSwitching: boolean) => string;
  i18nPreloginConfirm: (isSwitching: boolean) => string;
} {
  return {
    ...getAccountingIntegrationDetails(nativeIntegration),
    i18nPreloginTitle: 'bookkeep.integrations.netsuite.preloginModal.title',
    i18nPreloginSubtitle: () =>
      'bookkeep.integrations.netsuite.preloginModal.subtitle',
    i18nPreloginConfirm: () =>
      'bookkeep.integrations.netsuite.preloginModal.confirmButton',
  };
}

function getDetailsForChift(
  nativeIntegration: NativeAccountingIntegration,
): AccountingIntegrationDetails & {
  i18nPreloginTitle: string;
  i18nPreloginSubtitle: (isSwitching: boolean) => string;
  i18nPreloginConfirm: (isSwitching: boolean) => string;
} {
  return {
    ...getAccountingIntegrationDetails(nativeIntegration),
    i18nPreloginTitle: 'bookkeep.integrations.chift.preloginModal.title',
    i18nPreloginSubtitle: (isSwitching) =>
      isSwitching
        ? 'bookkeep.integrations.chift.preloginModal.subtitle.switching'
        : 'bookkeep.integrations.chift.preloginModal.subtitle.connecting',
    i18nPreloginConfirm: () =>
      'bookkeep.integrations.chift.preloginModal.confirmButton.buildConnection',
  };
}
