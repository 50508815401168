import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import {
  EntityStatisticBlock,
  EntityStatisticBlockSkeleton,
} from './EntityStatisticBlock/EntityStatisticBlock';
import { EntityStatisticItem } from './EntityStatisticBlock/EntityStatisticItem';
import { type EntityStatisticAction } from './EntityStatisticBlock/types';
import { type OrganisationReportingEntityStatusForCallout } from './OrganisationReportingEntityStatisticsCallout/OrganisationReportingEntityStatisticsCallout';
import { useOrganisationReportingEntityStatistics } from '../../../hooks/data/useOrganisationReportingEntityStatistics';
import { type OrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import type {
  OrganisationReportingEntity,
  OrganisationReportingStatistics,
} from '../../../types';

type Props = {
  entity: OrganisationReportingEntity;
  features: OrganisationFeatures;
  entityStatus: OrganisationReportingEntityStatusForCallout | undefined;
};

export const OrganisationReportingEntityActions = ({
  entity,
  features,
  entityStatus,
}: Props) => {
  const { t } = useTranslation('global');

  const entityStatisticsQueryState = useOrganisationReportingEntityStatistics(
    entity,
    features,
  );

  if (entityStatisticsQueryState.status !== 'success') {
    const availableActionScopes = [
      features.invoicesInbox,
      features.requests,
      features.invoices,
      features.expenseClaims,
      features.receipts.lateReceipts || features.receipts.missingReceipts,
      features.payables,
    ].filter(Boolean);

    return (
      <div className="flex items-stretch gap-xs">
        {[...Array(availableActionScopes.length).keys()].map((x) => (
          <EntityStatisticBlockSkeleton key={x} />
        ))}
      </div>
    );
  }

  // TODO: Remove this when we want to display churned entities
  // See https://spendesk.atlassian.net/browse/ECO-395
  if (entity.hasChurned) {
    return null;
  }

  const availableEntityActions = getEntityStatisticActions(
    entity.id,
    entityStatisticsQueryState.data,
    features,
    t,
  ).filter(({ isAvailable }) => isAvailable);

  const entityActionGroups = [
    ...new Set(availableEntityActions.map(({ group }) => group)),
  ];

  const statusesForInactiveState: OrganisationReportingEntityStatusForCallout[] =
    ['kycInProgress', 'awaitingKycApproval'] as const;
  const isInactive =
    entityStatus && statusesForInactiveState.includes(entityStatus);

  return (
    <div className="flex items-stretch gap-xs overflow-scroll">
      {entityActionGroups.map((group) => (
        <EntityStatisticBlock
          key={group}
          title={t(
            `organisation.reporting.page.entities.entity.statistics.${group}.title`,
          )}
          contents={[
            availableEntityActions
              .filter(({ group: actionGroup }) => actionGroup === group)
              .map(({ key, count, label, href, trackingEventName }) => (
                <EntityStatisticItem
                  key={key}
                  label={label}
                  count={
                    (isInactive || entityStatus === 'churning' ? 0 : count) ?? 0
                  }
                  href={href}
                  callback={
                    trackingEventName &&
                    (() => track(trackingEventName, { value: key }))
                  }
                  isInactive={
                    isInactive || entityStatus === 'churning' || count === 0
                  }
                />
              )),
          ]}
        />
      ))}
    </div>
  );
};

const DEFAULT_STAT_VALUE = 0;
const getEntityStatisticActions = (
  entityId: string,
  statistics: OrganisationReportingStatistics,
  features: OrganisationFeatures,
  t: TGlobalFunctionTyped,
): EntityStatisticAction[] => [
  // Inbox
  {
    group: 'inbox',
    key: 'inbox-toAssign',
    count: statistics.inboxInvoices.toAssign ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.inbox.groups.toAssign',
    ),
    href: routeFor(routes.INBOX_INVOICES.path, {
      company: entityId,
    }),
    isAvailable: features.invoicesInbox,
  },
  {
    group: 'inbox',
    key: 'inbox-awaiting',
    count: statistics.inboxInvoices.awaiting ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.inbox.groups.awaiting',
    ),
    href: routeFor(routes.INBOX_INVOICES.path, {
      company: entityId,
    }),
    isAvailable: features.invoicesInbox,
  },
  // Requests
  {
    group: 'requests',
    key: 'requests-toApprove',
    count: statistics.requests.toApprove ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.requests.groups.toApprove',
    ),
    href: routeFor(routes.REQUESTS.path, {
      company: entityId,
      type: 'to-approve',
    }),
    trackingEventName:
      AnalyticEventName.ORGANISATION_REPORTING_REQUESTS_TO_APPROVE_BUTTON_CLICKED,
    isAvailable: features.requests,
  },
  {
    group: 'requests',
    key: 'requests-total',
    count: statistics.requests.all ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.requests.groups.total',
    ),
    href: routeFor(routes.REQUESTS.path, {
      company: entityId,
      type: 'all',
    }),
    isAvailable: features.requests,
  },
  // Invoices
  {
    group: 'invoices',
    key: 'invoices-toReview',
    count: statistics.invoices.toReview ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.invoices.groups.toReview',
    ),
    href: routeFor(routes.INVOICES_REVIEW.path, {
      company: entityId,
    }),
    isAvailable: features.invoices,
  },
  {
    group: 'invoices',
    key: 'invoices-toSchedule',
    count: statistics.invoices.toSchedule ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.invoices.groups.toSchedule',
    ),
    href: routeFor(routes.INVOICES_PAY.path, {
      company: entityId,
    }),
    isAvailable: features.invoices,
  },
  {
    group: 'invoices',
    key: 'invoices-toConfirm',
    count: statistics.invoices.toConfirm ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.invoices.groups.toConfirm',
    ),
    href: routeFor(routes.INVOICES_CONFIRM.path, {
      company: entityId,
    }),
    trackingEventName:
      AnalyticEventName.ORGANISATION_REPORTING_INVOICES_TO_PAY_BUTTON_CLICKED,
    isAvailable: features.invoices,
  },
  // Expense claims
  {
    group: 'expenseClaims',
    key: 'expenseClaims-toReview',
    count: statistics.expenseClaims.toReview ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.expenseClaims.groups.toReview',
    ),
    href: routeFor(routes.EXPENSE_CLAIMS_REVIEW.path, {
      company: entityId,
    }),
    isAvailable: features.expenseClaims,
  },
  {
    group: 'expenseClaims',
    key: 'expenseClaims-toReimburse',
    count: statistics.expenseClaims.toReimburse ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.expenseClaims.groups.toReimburse',
    ),
    href: routeFor(routes.EXPENSE_CLAIMS_PAY.path, {
      company: entityId,
    }),
    isAvailable: features.expenseClaims,
  },
  {
    group: 'expenseClaims',
    key: 'expenseClaims-toConfirm',
    count: statistics.expenseClaims.toConfirm ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.expenseClaims.groups.toConfirm',
    ),
    href: routeFor(routes.EXPENSE_CLAIMS_CONFIRM.path, {
      company: entityId,
    }),
    isAvailable: features.expenseClaims,
  },
  // Receipts
  {
    group: 'receipts',
    key: 'receipts-late',
    count: statistics.receipts.late ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.receipts.groups.late',
    ),
    href: `${routeFor(routes.PAYMENTS_ALL.path, {
      company: entityId,
    })}?completionDeadline=late`,
    trackingEventName:
      AnalyticEventName.ORGANISATION_REPORTING_LATE_RECEIPTS_BUTTON_CLICKED,
    isAvailable: features.receipts.lateReceipts,
  },
  {
    group: 'receipts',
    key: 'receipts-missing',
    count: statistics.receipts.missing ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.receipts.groups.missing',
    ),
    href: `${routeFor(routes.PAYMENTS_ALL.path, {
      company: entityId,
    })}?invoice=missing`,
    isAvailable: features.receipts.missingReceipts,
  },
  // Payables
  {
    group: 'payables',
    key: 'payables-toPrepare',
    count: statistics.payables.toPrepare ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.payables.groups.toPrepare',
    ),
    href: routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
      company: entityId,
    }),
    trackingEventName:
      AnalyticEventName.ORGANISATION_REPORTING_PAYABLES_TO_REVIEW_BUTTON_CLICKED,
    isAvailable: features.payables,
  },
  {
    group: 'payables',
    key: 'payables-toExport',
    count: statistics.payables.toExport ?? DEFAULT_STAT_VALUE,
    label: t(
      'organisation.reporting.page.entities.entity.statistics.payables.groups.toExport',
    ),
    href: routeFor(routes.EXPENSE_INBOX_EXPORT.path, {
      company: entityId,
    }),
    isAvailable: features.payables,
  },
];
