import { useTranslation } from 'react-i18next';

import { CountryFlag } from 'src/core/common/components/CountryFlag/CountryFlag';
import { getCountryFromCode } from 'src/core/config/country';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { OrganisationReportingEntityPin } from './OrganisationReportingEntityPin/OrganisationReportingEntityPin';
import { type OrganisationReportingEntity } from '../../../types';

type Props = {
  entity: OrganisationReportingEntity;
  isPinned: boolean;
  togglePinnedEntity: (entityId: string) => void;
  canAccessEntity?: boolean;
};

export const OrganisationReportingEntityStatisticsHeader = ({
  entity: { country: countryCode, id, name },
  isPinned,
  togglePinnedEntity,
  canAccessEntity = true,
}: Props) => {
  const { t } = useTranslation('global');

  const country = getCountryFromCode(countryCode);
  const translatedCountry = country ? t(country.translationKey) : countryCode;

  return (
    <div className="flex items-center gap-s">
      <OrganisationReportingEntityPin
        isPinned={isPinned}
        togglePinnedEntity={() => {
          togglePinnedEntity(id);
        }}
      />
      <div className="flex items-center gap-xs">
        {canAccessEntity ? (
          <a
            key="name"
            href={routeFor(routes.HOMEPAGE.path, {
              company: id,
            })}
            target="_blank"
            rel="noreferrer"
            className="text-complementary underline title-l"
            onClick={() =>
              track(
                AnalyticEventName.ORGANISATION_REPORTING_OPEN_ENTITY_BUTTON_CLICKED,
              )
            }
          >
            {name}
          </a>
        ) : (
          <h3 className="text-complementary title-l">{name}</h3>
        )}
        <span className="text-neutral-darker body-l">•</span>
        <div key="country" className="flex items-center gap-xxs">
          <p className="text-neutral-darker body-l">{translatedCountry}</p>
          <CountryFlag country={countryCode} className="h-[18px] w-[18px]" />
        </div>
      </div>
    </div>
  );
};
